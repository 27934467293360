import React, { Component } from "react"
import LeftSideComponent from "./LeftSideComponent/LeftSideComponent"
import RightSideComponent from "./RightSideComponent/RightSideComponent"
import DefaultPageComponent from "./DefaultPageComponent/DefaultPageComponent"
import { connect } from "react-redux"
import { initialize } from "../../redux/actions/actions"
import "./style.css"

class MyWhatsApp extends Component {
  constructor(props) {
    super(props)
    this.state = { ...this.props }
  }
  projectInfo() {
    return {
      projectTitle: "User Interface like WhatsApp",
      projectDescription: "Web application like whatsApp.",
      referenceUrl: "https://web.whatsapp.com/",
      developer: "Vijay Mathur",
      createdAt: "30-03-2021",
      completedAt: "10-04-2021",
    }
  }

  //  after component is mounted then this function will be executed.
  componentDidMount() {
    this.props.initialize()
  }

  render() {
    return (
      <div className='flex-container'>
        <LeftSideComponent />
        {this.props.clicked ? <RightSideComponent /> : <DefaultPageComponent />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { ...state }
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: () => dispatch(initialize()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWhatsApp)
