const Main = () => {
  const first_block = [
    {
      href: "https://docs.microsoft.com/en-us/powershell/",
      innerText: "PowerShell Documentation",
      target: "_blank",
    },
    {
      href: "https://social.technet.microsoft.com/Forums/en-US/home?forum=ITCG",
      innerText: "Scripting Forums",
      target: "_blank",
    },
    {
      href:
        "https://social.technet.microsoft.com/Forums/en-US/home?forum=winserverpowershell",
      innerText: "PowerShell Forums",
      target: "_blank",
    },
    {
      href:
        "https://techcommunity.microsoft.com/t5/PowerShell/ct-p/WindowsPowerShell",
      innerText: "PowerShell on TechCommunity",
      target: "_blank",
    },
    {
      href: "https://powershell.org/",
      innerText: "PowerShell.org - Community Resource",
      target: "_blank",
    },
  ]
  const second_block = [
    {
      href: "https://devblogs.microsoft.com/scripting/tag/csv/",
      innerText: ".csv",
      title: "View all posts in .csv",
    },

    {
      href: "https://devblogs.microsoft.com/scripting/tag/net/",
      innerText: ".NET",
      title: "View all posts in .NET",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/net-core/",
      innerText: ".NET Core",
      title: "View all posts in .NET Core",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/net-framework/",
      innerText: ".NET Framework",
      title: "View all posts in .NET Framework",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/2009-summer-scripting-games/",
      innerText: "2009 Summer Scripting Games",
      title: "View all posts in 2009 Summer Scripting Games",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/2010-scripting-games/",
      innerText: "2010 Scripting Games",
      title: "View all posts in 2010 Scripting Games",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/2011-scripting-games/",
      innerText: "2011 Scripting Games",
      title: "View all posts in 2011 Scripting Games",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/2012-scripting-games/",
      innerText: "2012 Scripting Games",
      title: "View all posts in 2012 Scripting Games",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/2013-scripting-games/",
      innerText: "2013 Scripting Games",
      title: "View all posts in 2013 Scripting Games",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/2014-scripting-games/",
      innerText: "2014 Scripting Games",
      title: "View all posts in 2014 Scripting Games",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/2014-winter-scripting-games/",
      innerText: "2014 Winter Scripting Games",
      title: "View all posts in 2014 Winter Scripting Games",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/2015-holiday-series/",
      innerText: "2015 Holiday Series",
      title: "View all posts in 2015 Holiday Series",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/4-0/",
      innerText: "4.0",
      title: "View all posts in 4.0",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/70-410/",
      innerText: "70-410",
      title: "View all posts in 70-410",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/aaron-nelson/",
      innerText: "Aaron Nelson",
      title: "View all posts in Aaron Nelson",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/access-application/",
      innerText: "Access.Application",
      title: "View all posts in Access.Application",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/acls/",
      innerText: "ACLs",
      title: "View all posts in ACLs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/activation/",
      innerText: "activation",
      title: "View all posts in activation",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/active-directory/",
      innerText: "Active Directory",
      title: "View all posts in Active Directory",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/active-directory-application-mode-adam/",
      innerText: "Active Directory Application Mode (ADAM)",
      title: "View all posts in Active Directory Application Mode (ADAM)",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/activex-data-objects-ado/",
      innerText: "ActiveX Data Objects (ADO)",
      title: "View all posts in ActiveX Data Objects (ADO)",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/adam-bertram/",
      innerText: "Adam Bertram",
      title: "View all posts in Adam Bertram",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/adam-driscoll/",
      innerText: "Adam Driscoll",
      title: "View all posts in Adam Driscoll",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/adam-haynes/",
      innerText: "Adam Haynes",
      title: "View all posts in Adam Haynes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/admin-first-steps/",
      innerText: "Admin First Steps",
      title: "View all posts in Admin First Steps",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ado-net/",
      innerText: "ADO.NET",
      title: "View all posts in ADO.NET",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/adodb-recordset/",
      innerText: "ADODB.Recordset",
      title: "View all posts in ADODB.Recordset",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ador-recordset/",
      innerText: "ADOR.Recordset",
      title: "View all posts in ADOR.Recordset",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/adsi/",
      innerText: "ADSI",
      title: "View all posts in ADSI",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced/",
      innerText: "Advanced",
      title: "View all posts in Advanced",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-1/",
      innerText: "Advanced Event 1",
      title: "View all posts in Advanced Event 1",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-10/",
      innerText: "Advanced Event 10",
      title: "View all posts in Advanced Event 10",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-2/",
      innerText: "Advanced Event 2",
      title: "View all posts in Advanced Event 2",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-3/",
      innerText: "Advanced Event 3",
      title: "View all posts in Advanced Event 3",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-4/",
      innerText: "Advanced Event 4",
      title: "View all posts in Advanced Event 4",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-5/",
      innerText: "Advanced Event 5",
      title: "View all posts in Advanced Event 5",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-6/",
      innerText: "Advanced Event 6",
      title: "View all posts in Advanced Event 6",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-7/",
      innerText: "Advanced Event 7",
      title: "View all posts in Advanced Event 7",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-8/",
      innerText: "Advanced Event 8",
      title: "View all posts in Advanced Event 8",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-event-9/",
      innerText: "Advanced Event 9",
      title: "View all posts in Advanced Event 9",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/advanced-functions/",
      innerText: "Advanced Functions",
      title: "View all posts in Advanced Functions",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/alan-byrne/",
      innerText: "Alan Byrne",
      title: "View all posts in Alan Byrne",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/alan-morris/",
      innerText: "Alan Morris",
      title: "View all posts in Alan Morris",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/alex-verboon/",
      innerText: "Alex Verboon",
      title: "View all posts in Alex Verboon",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/and-jobs/",
      innerText: "and jobs",
      title: "View all posts in and jobs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/anders-wahlqvist/",
      innerText: "Anders Wahlqvist",
      title: "View all posts in Anders Wahlqvist",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/android/",
      innerText: "Android",
      title: "View all posts in Android",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/andy-schneider/",
      innerText: "Andy Schneider",
      title: "View all posts in Andy Schneider",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/anonymous/",
      innerText: "anonymous",
      title: "View all posts in anonymous",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/antivirus/",
      innerText: "Antivirus",
      title: "View all posts in Antivirus",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/app-v/",
      innerText: "App-V",
      title: "View all posts in App-V",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/applications/",
      innerText: "applications",
      title: "View all posts in applications",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/arnaud-petitjean/",
      innerText: "Arnaud Petitjean",
      title: "View all posts in Arnaud Petitjean",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/array/",
      innerText: "Array",
      title: "View all posts in Array",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/arrays/",
      innerText: "Arrays",
      title: "View all posts in Arrays",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/arrays-hash-tables/",
      innerText: "arrays hash tables",
      title: "View all posts in arrays hash tables",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/arrays-hash-tables-and-dictionary-objects/",
      innerText: "arrays hash tables and dictionary objects",
      title: "View all posts in arrays hash tables and dictionary objects",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/arrays-hashtables-and-dictionary-objects/",
      innerText: "arrays hashtables and dictionary objects",
      title: "View all posts in arrays hashtables and dictionary objects",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ascii/",
      innerText: "ASCII",
      title: "View all posts in ASCII",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ashley-mcglone/",
      innerText: "Ashley McGlone",
      title: "View all posts in Ashley McGlone",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/asia-gandecka/",
      innerText: "Asia Gandecka",
      title: "View all posts in Asia Gandecka",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/asp-net-core/",
      innerText: "ASP.NET Core",
      title: "View all posts in ASP.NET Core",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/associators-of/",
      innerText: "associators of",
      title: "View all posts in associators of",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/attributes/",
      innerText: "Attributes",
      title: "View all posts in Attributes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/audio/",
      innerText: "Audio",
      title: "View all posts in Audio",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/authors/",
      innerText: "Authors",
      title: "View all posts in Authors",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/automatic-variables/",
      innerText: "Automatic Variables",
      title: "View all posts in Automatic Variables",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/automation/",
      innerText: "Automation",
      title: "View all posts in Automation",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/azure/",
      innerText: "Azure",
      title: "View all posts in Azure",
    },

    {
      href: "https://devblogs.microsoft.com/scripting/tag/azure-automation/",
      innerText: "Azure Automation",
      title: "View all posts in Azure Automation",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/azuread/",
      innerText: "AzureAD",
      title: "View all posts in AzureAD",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/background-jobs/",
      innerText: "background jobs",
      title: "View all posts in background jobs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/backup/",
      innerText: "backup",
      title: "View all posts in backup",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/backup-and-system-restore/",
      innerText: "backup and system restore",
      title: "View all posts in backup and system restore",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/badge/",
      innerText: "badge",
      title: "View all posts in badge",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bartek-bielawski/",
      innerText: "Bartek Bielawski",
      title: "View all posts in Bartek Bielawski",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bash/",
      innerText: "Bash",
      title: "View all posts in Bash",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/basic-computer-information/",
      innerText: "basic computer information",
      title: "View all posts in basic computer information",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/basic-hardware-and-firmware/",
      innerText: "basic hardware and firmware",
      title: "View all posts in basic hardware and firmware",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/basic-hardware-information/",
      innerText: "Basic Hardware information",
      title: "View all posts in Basic Hardware information",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/basic-information/",
      innerText: "basic information",
      title: "View all posts in basic information",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/basics/",
      innerText: "Basics",
      title: "View all posts in Basics",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/batchman/",
      innerText: "BATCHman",
      title: "View all posts in BATCHman",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/batteries-and-power-supplies/",
      innerText: "batteries and power supplies",
      title: "View all posts in batteries and power supplies",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner/",
      innerText: "Beginner",
      title: "View all posts in Beginner",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-1/",
      innerText: "Beginner Event 1",
      title: "View all posts in Beginner Event 1",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-10/",
      innerText: "Beginner Event 10",
      title: "View all posts in Beginner Event 10",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-2/",
      innerText: "Beginner Event 2",
      title: "View all posts in Beginner Event 2",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-3/",
      innerText: "Beginner Event 3",
      title: "View all posts in Beginner Event 3",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-4/",
      innerText: "Beginner Event 4",
      title: "View all posts in Beginner Event 4",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-5/",
      innerText: "Beginner Event 5",
      title: "View all posts in Beginner Event 5",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-6/",
      innerText: "Beginner Event 6",
      title: "View all posts in Beginner Event 6",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-7/",
      innerText: "Beginner Event 7",
      title: "View all posts in Beginner Event 7",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-8/",
      innerText: "Beginner Event 8",
      title: "View all posts in Beginner Event 8",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/beginner-event-9/",
      innerText: "Beginner Event 9",
      title: "View all posts in Beginner Event 9",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ben-pearce/",
      innerText: "Ben Pearce",
      title: "View all posts in Ben Pearce",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ben-vierck/",
      innerText: "Ben Vierck",
      title: "View all posts in Ben Vierck",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/best-practice/",
      innerText: "Best Practice",
      title: "View all posts in Best Practice",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/best-practices/",
      innerText: "best practices",
      title: "View all posts in best practices",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bhargav-shukla/",
      innerText: "Bhargav Shukla",
      title: "View all posts in Bhargav Shukla",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bill-grauer/",
      innerText: "Bill Grauer",
      title: "View all posts in Bill Grauer",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bill-stewart/",
      innerText: "Bill Stewart",
      title: "View all posts in Bill Stewart",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bitlocker/",
      innerText: "BitLocker",
      title: "View all posts in BitLocker",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bits/",
      innerText: "BITS",
      title: "View all posts in BITS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bob-stevens/",
      innerText: "Bob Stevens",
      title: "View all posts in Bob Stevens",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/boe-prox/",
      innerText: "Boe Prox",
      title: "View all posts in Boe Prox",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/brandon-shell/",
      innerText: "Brandon Shell",
      title: "View all posts in Brandon Shell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/breakpoint/",
      innerText: "breakpoint",
      title: "View all posts in breakpoint",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/brent-forman/",
      innerText: "Brent Forman",
      title: "View all posts in Brent Forman",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/brian-hitney/",
      innerText: "Brian Hitney",
      title: "View all posts in Brian Hitney",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/brian-jackett/",
      innerText: "Brian Jackett",
      title: "View all posts in Brian Jackett",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/brian-lalancette/",
      innerText: "Brian Lalancette",
      title: "View all posts in Brian Lalancette",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/brian-wilhite/",
      innerText: "Brian Wilhite",
      title: "View all posts in Brian Wilhite",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/brian-wren/",
      innerText: "Brian Wren",
      title: "View all posts in Brian Wren",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/bruce-payette/",
      innerText: "Bruce Payette",
      title: "View all posts in Bruce Payette",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/buck-woody/",
      innerText: "Buck Woody",
      title: "View all posts in Buck Woody",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/build-your-own-cmdlet/",
      innerText: "Build your own cmdlet",
      title: "View all posts in Build your own cmdlet",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/c/",
      innerText: "C#",
      title: "View all posts in C#",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/certificate/",
      innerText: "Certificate",
      title: "View all posts in Certificate",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/certificates/",
      innerText: "certificates",
      title: "View all posts in certificates",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chad-miller/",
      innerText: "Chad Miller",
      title: "View all posts in Chad Miller",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/change-journal/",
      innerText: "change journal",
      title: "View all posts in change journal",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/chendrayan-venkatesan/",
      innerText: "Chendrayan Venkatesan",
      title: "View all posts in Chendrayan Venkatesan",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chris-bellee/",
      innerText: "Chris Bellee",
      title: "View all posts in Chris Bellee",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chris-campbell/",
      innerText: "Chris Campbell",
      title: "View all posts in Chris Campbell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chris-conte/",
      innerText: "Chris Conte",
      title: "View all posts in Chris Conte",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chris-dent/",
      innerText: "Chris Dent",
      title: "View all posts in Chris Dent",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chris-oprey/",
      innerText: "Chris OPrey",
      title: "View all posts in Chris OPrey",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chris-weaver/",
      innerText: "Chris Weaver",
      title: "View all posts in Chris Weaver",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chris-wu/",
      innerText: "Chris Wu",
      title: "View all posts in Chris Wu",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/chrissy-lemaire/",
      innerText: "Chrissy LeMaire",
      title: "View all posts in Chrissy LeMaire",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/christopher-weaver/",
      innerText: "Christopher Weaver",
      title: "View all posts in Christopher Weaver",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/cim/",
      innerText: "CIM",
      title: "View all posts in CIM",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/classes/",
      innerText: "Classes",
      title: "View all posts in Classes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/claudia-ferguson/",
      innerText: "Claudia Ferguson",
      title: "View all posts in Claudia Ferguson",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/client-applications/",
      innerText: "client applications",
      title: "View all posts in client applications",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/client-side-management/",
      innerText: "client-side management",
      title: "View all posts in client-side management",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/client-side-printing/",
      innerText: "client-side printing",
      title: "View all posts in client-side printing",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/clint-huffman/",
      innerText: "Clint Huffman",
      title: "View all posts in Clint Huffman",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/clinton-knight/",
      innerText: "Clinton Knight",
      title: "View all posts in Clinton Knight",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/cloud/",
      innerText: "cloud",
      title: "View all posts in cloud",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/cloud-services/",
      innerText: "Cloud Services",
      title: "View all posts in Cloud Services",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/color/",
      innerText: "Color",
      title: "View all posts in Color",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/com/",
      innerText: "COM",
      title: "View all posts in COM",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/com-and-dcom/",
      innerText: "COM and DCOM",
      title: "View all posts in COM and DCOM",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/comma-and-other-deliminited-files/",
      innerText: "Comma and other deliminited files",
      title: "View all posts in Comma and other deliminited files",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/comma-and-other-delimited-files/",
      innerText: "Comma and other delimited files",
      title: "View all posts in Comma and other delimited files",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/comma-separated-and-other-delimited-files/",
      innerText: "Comma separated and other delimited files",
      title: "View all posts in Comma separated and other delimited files",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/comments/",
      innerText: "comments",
      title: "View all posts in comments",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/community/",
      innerText: "community",
      title: "View all posts in community",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/compare-object/",
      innerText: "Compare-Object",
      title: "View all posts in Compare-Object",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/compressed-files/",
      innerText: "compressed files",
      title: "View all posts in compressed files",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/computer-accounts/",
      innerText: "computer accounts",
      title: "View all posts in computer accounts",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/computers/",
      innerText: "Computers",
      title: "View all posts in Computers",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/configuration-manager/",
      innerText: "Configuration Manager",
      title: "View all posts in Configuration Manager",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/connect/",
      innerText: "Connect",
      title: "View all posts in Connect",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/console/",
      innerText: "console",
      title: "View all posts in console",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/continuous-delivery/",
      innerText: "Continuous Delivery",
      title: "View all posts in Continuous Delivery",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/continuous-integration/",
      innerText: "Continuous Integration",
      title: "View all posts in Continuous Integration",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/corey-roth/",
      innerText: "Corey Roth",
      title: "View all posts in Corey Roth",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/cpu/",
      innerText: "CPU",
      title: "View all posts in CPU",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/credential-guard/",
      innerText: "Credential Guard",
      title: "View all posts in Credential Guard",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/cryptography/",
      innerText: "Cryptography",
      title: "View all posts in Cryptography",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/csv-and-other-delimited-files/",
      innerText: "CSV and other delimited files",
      title: "View all posts in CSV and other delimited files",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dacls-and-sacls/",
      innerText: "DACLS and SACLS",
      title: "View all posts in DACLS and SACLS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dan-reist/",
      innerText: "Dan Reist",
      title: "View all posts in Dan Reist",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dan-sheehan/",
      innerText: "Dan Sheehan",
      title: "View all posts in Dan Sheehan",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/daniel-cruz/",
      innerText: "Daniel Cruz",
      title: "View all posts in Daniel Cruz",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/data/",
      innerText: "Data",
      title: "View all posts in Data",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/databases/",
      innerText: "databases",
      title: "View all posts in databases",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/datagrid/",
      innerText: "DataGrid",
      title: "View all posts in DataGrid",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dates/",
      innerText: "dates",
      title: "View all posts in dates",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dates-and-times/",
      innerText: "dates and times",
      title: "View all posts in dates and times",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dave-bishop/",
      innerText: "Dave Bishop",
      title: "View all posts in Dave Bishop",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dave-wyatt/",
      innerText: "Dave Wyatt",
      title: "View all posts in Dave Wyatt",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/david-moravec/",
      innerText: "David Moravec",
      title: "View all posts in David Moravec",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/david-obrien/",
      innerText: "David O’Brien",
      title: "View all posts in David O’Brien",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/debug/",
      innerText: "debug",
      title: "View all posts in debug",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/debugger/",
      innerText: "debugger",
      title: "View all posts in debugger",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/debugging/",
      innerText: "debugging",
      title: "View all posts in debugging",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/debugging-and-diagnostics/",
      innerText: "Debugging and Diagnostics",
      title: "View all posts in Debugging and Diagnostics",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/defender/",
      innerText: "Defender",
      title: "View all posts in Defender",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dennis-whitney/",
      innerText: "Dennis Whitney",
      title: "View all posts in Dennis Whitney",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/deployment-and-upgrade/",
      innerText: "Deployment and Upgrade",
      title: "View all posts in Deployment and Upgrade",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/desktop/",
      innerText: "Desktop",
      title: "View all posts in Desktop",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/desktop-management/",
      innerText: "desktop management",
      title: "View all posts in desktop management",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/desktop-mgmt/",
      innerText: "Desktop Mgmt",
      title: "View all posts in Desktop Mgmt",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/desktop-tasks/",
      innerText: "Desktop Tasks",
      title: "View all posts in Desktop Tasks",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dhcp/",
      innerText: "DHCP",
      title: "View all posts in DHCP",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dhcp-server/",
      innerText: "DHCP server",
      title: "View all posts in DHCP server",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dia-reeves/",
      innerText: "Dia Reeves",
      title: "View all posts in Dia Reeves",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dialog-boxes/",
      innerText: "dialog boxes",
      title: "View all posts in dialog boxes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/directories/",
      innerText: "directories",
      title: "View all posts in directories",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/directory/",
      innerText: "Directory",
      title: "View all posts in Directory",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/disk-drives-and-volumes/",
      innerText: "disk drives and volumes",
      title: "View all posts in disk drives and volumes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/disk-quotas/",
      innerText: "disk quotas",
      title: "View all posts in disk quotas",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/disks/",
      innerText: "Disks",
      title: "View all posts in Disks",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dism/",
      innerText: "DISM",
      title: "View all posts in DISM",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/displaying-output/",
      innerText: "displaying output",
      title: "View all posts in displaying output",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dns/",
      innerText: "DNS",
      title: "View all posts in DNS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dns-server/",
      innerText: "DNS server",
      title: "View all posts in DNS server",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/docker/",
      innerText: "Docker",
      title: "View all posts in Docker",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/doctor-scripto/",
      innerText: "Doctor Scripto",
      title: "View all posts in Doctor Scripto",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/domain-controllers/",
      innerText: "Domain Controllers",
      title: "View all posts in Domain Controllers",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/domains/",
      innerText: "domains",
      title: "View all posts in domains",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/don-gill/",
      innerText: "Don Gill",
      title: "View all posts in Don Gill",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/don-jones/",
      innerText: "Don Jones",
      title: "View all posts in Don Jones",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/don-walker/",
      innerText: "Don Walker",
      title: "View all posts in Don Walker",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/doug-finke/",
      innerText: "Doug Finke",
      title: "View all posts in Doug Finke",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/downloading-files/",
      innerText: "downloading files",
      title: "View all posts in downloading files",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/dsc/",
      innerText: "DSC",
      title: "View all posts in DSC",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/due-dates/",
      innerText: "due dates",
      title: "View all posts in due dates",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ed-wilson/",
      innerText: "ed wilson",
      title: "View all posts in ed wilson",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/edge/",
      innerText: "Edge",
      title: "View all posts in Edge",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/editor/",
      innerText: "editor",
      title: "View all posts in editor",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/endpoint/",
      innerText: "endpoint",
      title: "View all posts in endpoint",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/enum/",
      innerText: "enum",
      title: "View all posts in enum",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/enums/",
      innerText: "Enums",
      title: "View all posts in Enums",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/environment/",
      innerText: "Environment",
      title: "View all posts in Environment",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/environmental-variable/",
      innerText: "environmental variable",
      title: "View all posts in environmental variable",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/eric-wright/",
      innerText: "Eric Wright",
      title: "View all posts in Eric Wright",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/error-handling/",
      innerText: "error handling",
      title: "View all posts in error handling",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-1/",
      innerText: "Event 1",
      title: "View all posts in Event 1",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-10/",
      innerText: "Event 10",
      title: "View all posts in Event 10",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-2/",
      innerText: "Event 2",
      title: "View all posts in Event 2",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-3/",
      innerText: "Event 3",
      title: "View all posts in Event 3",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-4/",
      innerText: "Event 4",
      title: "View all posts in Event 4",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-5/",
      innerText: "Event 5",
      title: "View all posts in Event 5",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-6/",
      innerText: "Event 6",
      title: "View all posts in Event 6",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-7/",
      innerText: "Event 7",
      title: "View all posts in Event 7",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-8/",
      innerText: "Event 8",
      title: "View all posts in Event 8",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-9/",
      innerText: "Event 9",
      title: "View all posts in Event 9",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/event-logs/",
      innerText: "event logs",
      title: "View all posts in event logs",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/event-logs-and-monitoring/",
      innerText: "event logs and monitoring",
      title: "View all posts in event logs and monitoring",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/eventing-and-monitoring/",
      innerText: "Eventing and Monitoring",
      title: "View all posts in Eventing and Monitoring",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/events/",
      innerText: "Events",
      title: "View all posts in Events",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/events-and-logging/",
      innerText: "Events and Logging",
      title: "View all posts in Events and Logging",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/events-and-monitoring/",
      innerText: "events and monitoring",
      title: "View all posts in events and monitoring",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/excel-spreadsheet/",
      innerText: "Excel spreadsheet",
      title: "View all posts in Excel spreadsheet",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/excel-application/",
      innerText: "Excel.Application",
      title: "View all posts in Excel.Application",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/exchange/",
      innerText: "Exchange",
      title: "View all posts in Exchange",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/exchange-2010/",
      innerText: "Exchange 2010",
      title: "View all posts in Exchange 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/exchange-online/",
      innerText: "Exchange Online",
      title: "View all posts in Exchange Online",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/expert-commentators/",
      innerText: "expert commentators",
      title: "View all posts in expert commentators",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/extensions/",
      innerText: "Extensions",
      title: "View all posts in Extensions",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/f/",
      innerText: "F#",
      title: "View all posts in F#",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/facebook/",
      innerText: "Facebook",
      title: "View all posts in Facebook",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/faq/",
      innerText: "faq",
      title: "View all posts in faq",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/fast-esp-5-3-sp3/",
      innerText: "FAST ESP 5.3 SP3",
      title: "View all posts in FAST ESP 5.3 SP3",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/file/",
      innerText: "File",
      title: "View all posts in File",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/files/",
      innerText: "files",
      title: "View all posts in files",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/files-and-folders/",
      innerText: "files and folders",
      title: "View all posts in files and folders",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/filesystem/",
      innerText: "filesystem",
      title: "View all posts in filesystem",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/filesystemobject/",
      innerText: "FileSystemObject",
      title: "View all posts in FileSystemObject",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/firewall/",
      innerText: "firewall",
      title: "View all posts in firewall",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/folders/",
      innerText: "folders",
      title: "View all posts in folders",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/formatting-output/",
      innerText: "Formatting output",
      title: "View all posts in Formatting output",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/functions/",
      innerText: "functions",
      title: "View all posts in functions",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/fundamentals/",
      innerText: "Fundamentals",
      title: "View all posts in Fundamentals",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/gary-jackson/",
      innerText: "Gary Jackson",
      title: "View all posts in Gary Jackson",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/gary-siepser/",
      innerText: "Gary Siepser",
      title: "View all posts in Gary Siepser",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/general-management/",
      innerText: "General Management",
      title: "View all posts in General Management",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/general-management-tasks/",
      innerText: "general management tasks",
      title: "View all posts in general management tasks",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/general-mgmt-tasks/",
      innerText: "General Mgmt Tasks",
      title: "View all posts in General Mgmt Tasks",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/georges-maheu/",
      innerText: "Georges Maheu",
      title: "View all posts in Georges Maheu",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/gershon-levitz/",
      innerText: "Gershon Levitz",
      title: "View all posts in Gershon Levitz",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/get-random/",
      innerText: "Get-Random",
      title: "View all posts in Get-Random",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/getting-started/",
      innerText: "getting started",
      title: "View all posts in getting started",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/glenn-sizemore/",
      innerText: "Glenn Sizemore",
      title: "View all posts in Glenn Sizemore",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/glyn-clough/",
      innerText: "Glyn Clough",
      title: "View all posts in Glyn Clough",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/graphical/",
      innerText: "graphical",
      title: "View all posts in graphical",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/graphics/",
      innerText: "graphics",
      title: "View all posts in graphics",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/greg-lindsay/",
      innerText: "Greg Lindsay",
      title: "View all posts in Greg Lindsay",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/group-policy/",
      innerText: "Group Policy",
      title: "View all posts in Group Policy",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/grouping/",
      innerText: "Grouping",
      title: "View all posts in Grouping",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/groups/",
      innerText: "groups",
      title: "View all posts in groups",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/guest-blogger/",
      innerText: "guest blogger",
      title: "View all posts in guest blogger",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/guestblogger/",
      innerText: "GuestBlogger",
      title: "View all posts in GuestBlogger",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/gui/",
      innerText: "GUI",
      title: "View all posts in GUI",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/hardware/",
      innerText: "hardware",
      title: "View all posts in hardware",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/hash/",
      innerText: "hash",
      title: "View all posts in hash",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/hash-tables/",
      innerText: "hash tables",
      title: "View all posts in hash tables",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/hashtables/",
      innerText: "HashTables",
      title: "View all posts in HashTables",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/hashtables-and-dictionary-objects/",
      innerText: "hashtables and dictionary objects",
      title: "View all posts in hashtables and dictionary objects",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/heath-lawson/",
      innerText: "Heath Lawson",
      title: "View all posts in Heath Lawson",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/help/",
      innerText: "Help",
      title: "View all posts in Help",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/hey-scripting-guy/",
      innerText: "Hey Scripting Guy",
      title: "View all posts in Hey Scripting Guy",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/historical-debugging/",
      innerText: "Historical Debugging",
      title: "View all posts in Historical Debugging",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/history/",
      innerText: "History",
      title: "View all posts in History",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/holiday-2013/",
      innerText: "holiday 2013",
      title: "View all posts in holiday 2013",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/honorary-scripting-guys/",
      innerText: "Honorary Scripting Guys",
      title: "View all posts in Honorary Scripting Guys",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/hyper-v/",
      innerText: "Hyper-V",
      title: "View all posts in Hyper-V",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/hyperv/",
      innerText: "HyperV",
      title: "View all posts in HyperV",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/i-was-there/",
      innerText: "I was there",
      title: "View all posts in I was there",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ian-farr/",
      innerText: "Ian Farr",
      title: "View all posts in Ian Farr",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ignite/",
      innerText: "ignite",
      title: "View all posts in ignite",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ignite-2015/",
      innerText: "Ignite 2015",
      title: "View all posts in Ignite 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ignite-2016/",
      innerText: "Ignite 2016",
      title: "View all posts in Ignite 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/iis/",
      innerText: "IIS",
      title: "View all posts in IIS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ingo-karstein/",
      innerText: "Ingo Karstein",
      title: "View all posts in Ingo Karstein",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/input/",
      innerText: "input",
      title: "View all posts in input",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/install/",
      innerText: "Install",
      title: "View all posts in Install",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/intellisense/",
      innerText: "Intellisense",
      title: "View all posts in Intellisense",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/internet/",
      innerText: "internet",
      title: "View all posts in internet",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/internet-explorer/",
      innerText: "Internet Explorer",
      title: "View all posts in Internet Explorer",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/internet-explorer-7/",
      innerText: "Internet Explorer 7",
      title: "View all posts in Internet Explorer 7",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/internet-search/",
      innerText: "Internet Search",
      title: "View all posts in Internet Search",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/invoke-restmethod/",
      innerText: "Invoke-RestMethod",
      title: "View all posts in Invoke-RestMethod",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/invoke-webrequest/",
      innerText: "Invoke-WebRequest",
      title: "View all posts in Invoke-WebRequest",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ios/",
      innerText: "iOS",
      title: "View all posts in iOS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/iot/",
      innerText: "IoT",
      title: "View all posts in IoT",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ise/",
      innerText: "ISE",
      title: "View all posts in ISE",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ise-editing/",
      innerText: "ISE editing",
      title: "View all posts in ISE editing",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/jakob-gottlieb-svendsen/",
      innerText: "Jakob Gottlieb Svendsen",
      title: "View all posts in Jakob Gottlieb Svendsen",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/james-brundage/",
      innerText: "James Brundage",
      title: "View all posts in James Brundage",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/james-kehr/",
      innerText: "James Kehr",
      title: "View all posts in James Kehr",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/james-oneill/",
      innerText: "James O’Neill",
      title: "View all posts in James O’Neill",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jan-egil-ring/",
      innerText: "Jan Egil Ring",
      title: "View all posts in Jan Egil Ring",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jason-helmick/",
      innerText: "Jason Helmick",
      title: "View all posts in Jason Helmick",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jason-hofferle/",
      innerText: "Jason Hofferle",
      title: "View all posts in Jason Hofferle",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jason-morgan/",
      innerText: "Jason Morgan",
      title: "View all posts in Jason Morgan",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jason-ryberg/",
      innerText: "Jason Ryberg",
      title: "View all posts in Jason Ryberg",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jason-walker/",
      innerText: "Jason Walker",
      title: "View all posts in Jason Walker",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/java/",
      innerText: "Java",
      title: "View all posts in Java",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jd-platek/",
      innerText: "JD Platek",
      title: "View all posts in JD Platek",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jeff-wouters/",
      innerText: "Jeff Wouters",
      title: "View all posts in Jeff Wouters",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jeffery-hicks/",
      innerText: "Jeffery Hicks",
      title: "View all posts in Jeffery Hicks",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jeremy-engel/",
      innerText: "Jeremy Engel",
      title: "View all posts in Jeremy Engel",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jim-christopher/",
      innerText: "Jim Christopher",
      title: "View all posts in Jim Christopher",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jobs/",
      innerText: "jobs",
      title: "View all posts in jobs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/joel-bennett/",
      innerText: "Joel Bennett",
      title: "View all posts in Joel Bennett",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/joel-stidley/",
      innerText: "Joel Stidley",
      title: "View all posts in Joel Stidley",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/joel-vickery/",
      innerText: "Joel Vickery",
      title: "View all posts in Joel Vickery",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/john-ferringer/",
      innerText: "John Ferringer",
      title: "View all posts in John Ferringer",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/john-slack/",
      innerText: "John Slack",
      title: "View all posts in John Slack",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jon-newman/",
      innerText: "Jon Newman",
      title: "View all posts in Jon Newman",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jonathan-allen/",
      innerText: "Jonathan Allen",
      title: "View all posts in Jonathan Allen",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jonathan-medd/",
      innerText: "Jonathan Medd",
      title: "View all posts in Jonathan Medd",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jonathan-tyler/",
      innerText: "Jonathan Tyler",
      title: "View all posts in Jonathan Tyler",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/jonathan-yong/",
      innerText: "Jonathan Yong",
      title: "View all posts in Jonathan Yong",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/josh-atwell/",
      innerText: "Josh Atwell",
      title: "View all posts in Josh Atwell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/josh-gavant/",
      innerText: "Josh Gavant",
      title: "View all posts in Josh Gavant",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/json/",
      innerText: "Json",
      title: "View all posts in Json",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/judges/",
      innerText: "judges",
      title: "View all posts in judges",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/judging-criteria/",
      innerText: "judging criteria",
      title: "View all posts in judging criteria",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/judith-herman/",
      innerText: "Judith Herman",
      title: "View all posts in Judith Herman",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/june-blender/",
      innerText: "June Blender",
      title: "View all posts in June Blender",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/justin-hall/",
      innerText: "Justin Hall",
      title: "View all posts in Justin Hall",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/k-scripting-techniques/",
      innerText: "k Scripting Techniques",
      title: "View all posts in k Scripting Techniques",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/karl-mitschke/",
      innerText: "Karl Mitschke",
      title: "View all posts in Karl Mitschke",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/keith-hill/",
      innerText: "Keith HIll",
      title: "View all posts in Keith HIll",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/keith-mayer/",
      innerText: "Keith Mayer",
      title: "View all posts in Keith Mayer",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ken-mcferron/",
      innerText: "Ken McFerron",
      title: "View all posts in Ken McFerron",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/kendal-vandyke/",
      innerText: "Kendal VanDyke",
      title: "View all posts in Kendal VanDyke",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/kevin-kline/",
      innerText: "Kevin Kline",
      title: "View all posts in Kevin Kline",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/kirk-munro/",
      innerText: "Kirk Munro",
      title: "View all posts in Kirk Munro",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/klaus-schulte/",
      innerText: "Klaus Schulte",
      title: "View all posts in Klaus Schulte",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/kory-thacher/",
      innerText: "Kory Thacher",
      title: "View all posts in Kory Thacher",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/koryt/",
      innerText: "koryt",
      title: "View all posts in koryt",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/laerte-junior/",
      innerText: "Laerte Junior",
      title: "View all posts in Laerte Junior",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/leaderboard/",
      innerText: "leaderboard",
      title: "View all posts in leaderboard",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/lee-holmes/",
      innerText: "Lee Holmes",
      title: "View all posts in Lee Holmes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/lido-paglia/",
      innerText: "Lido Paglia",
      title: "View all posts in Lido Paglia",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/linux/",
      innerText: "LINUX",
      title: "View all posts in LINUX",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/local-account-management/",
      innerText: "local account management",
      title: "View all posts in local account management",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/local-accounts/",
      innerText: "local accounts",
      title: "View all posts in local accounts",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/local-accounts-and-windows-nt-4-0-accounts/",
      innerText: "local accounts and Windows NT 4.0 accounts",
      title: "View all posts in local accounts and Windows NT 4.0 accounts",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/local-user-account-management/",
      innerText: "local user account management",
      title: "View all posts in local user account management",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/log-analytics/",
      innerText: "Log Analytics",
      title: "View all posts in Log Analytics",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/log-parser/",
      innerText: "log parser",
      title: "View all posts in log parser",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/logging-and-events/",
      innerText: "Logging and events",
      title: "View all posts in Logging and events",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/logon-sessions/",
      innerText: "logon sessions",
      title: "View all posts in logon sessions",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/logs/",
      innerText: "logs",
      title: "View all posts in logs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/logs-and-logging/",
      innerText: "Logs and logging",
      title: "View all posts in Logs and logging",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/logs-and-monitoring/",
      innerText: "Logs and monitoring",
      title: "View all posts in Logs and monitoring",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/looping/",
      innerText: "looping",
      title: "View all posts in looping",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/lync/",
      innerText: "LYNC",
      title: "View all posts in LYNC",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/management/",
      innerText: "Management",
      title: "View all posts in Management",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/marc-carter/",
      innerText: "Marc Carter",
      title: "View all posts in Marc Carter",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/marc-van-orsouw/",
      innerText: "Marc van Orsouw",
      title: "View all posts in Marc van Orsouw",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/marco-shaw/",
      innerText: "Marco Shaw",
      title: "View all posts in Marco Shaw",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mark-morowczynski/",
      innerText: "Mark Morowczynski",
      title: "View all posts in Mark Morowczynski",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mark-schill/",
      innerText: "Mark Schill",
      title: "View all posts in Mark Schill",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mark-tabladillo/",
      innerText: "Mark Tabladillo",
      title: "View all posts in Mark Tabladillo",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/masking-passwords/",
      innerText: "masking passwords",
      title: "View all posts in masking passwords",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/math/",
      innerText: "math",
      title: "View all posts in math",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/matt-bongiovi/",
      innerText: "Matt Bongiovi",
      title: "View all posts in Matt Bongiovi",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/matt-graeber/",
      innerText: "Matt Graeber",
      title: "View all posts in Matt Graeber",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/matt-hester/",
      innerText: "Matt Hester",
      title: "View all posts in Matt Hester",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/matt-hitchcock/",
      innerText: "Matt Hitchcock",
      title: "View all posts in Matt Hitchcock",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/matt-tisdale/",
      innerText: "Matt Tisdale",
      title: "View all posts in Matt Tisdale",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/matthew-hitchcock/",
      innerText: "Matthew Hitchcock",
      title: "View all posts in Matthew Hitchcock",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/matthew-kerfoot/",
      innerText: "Matthew Kerfoot",
      title: "View all posts in Matthew Kerfoot",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mcse/",
      innerText: "MCSE",
      title: "View all posts in MCSE",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mdt/",
      innerText: "MDT",
      title: "View all posts in MDT",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/media/",
      innerText: "Media",
      title: "View all posts in Media",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/messaging-communication/",
      innerText: "Messaging & Communication",
      title: "View all posts in Messaging & Communication",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/messaging-and-communication/",
      innerText: "messaging and communication",
      title: "View all posts in messaging and communication",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/metadata/",
      innerText: "Metadata",
      title: "View all posts in Metadata",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/michael-blumenthal/",
      innerText: "Michael Blumenthal",
      title: "View all posts in Michael Blumenthal",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/michael-frommhold/",
      innerText: "Michael Frommhold",
      title: "View all posts in Michael Frommhold",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/michael-wells/",
      innerText: "Michael Wells",
      title: "View all posts in Michael Wells",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/microsoft-365/",
      innerText: "Microsoft 365",
      title: "View all posts in Microsoft 365",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/microsoft-access/",
      innerText: "Microsoft Access",
      title: "View all posts in Microsoft Access",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/microsoft-excel/",
      innerText: "Microsoft Excel",
      title: "View all posts in Microsoft Excel",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/microsoft-exchange-2003/",
      innerText: "Microsoft Exchange 2003",
      title: "View all posts in Microsoft Exchange 2003",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/microsoft-exchange-2007/",
      innerText: "Microsoft Exchange 2007",
      title: "View all posts in Microsoft Exchange 2007",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/microsoft-exchange-2010/",
      innerText: "Microsoft Exchange 2010",
      title: "View all posts in Microsoft Exchange 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/microsoft-office/",
      innerText: "Microsoft Office",
      title: "View all posts in Microsoft Office",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/microsoft-outlook/",
      innerText: "Microsoft Outlook",
      title: "View all posts in Microsoft Outlook",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/microsoft-powerpoint/",
      innerText: "Microsoft PowerPoint",
      title: "View all posts in Microsoft PowerPoint",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/microsoft-visio/",
      innerText: "Microsoft Visio",
      title: "View all posts in Microsoft Visio",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/microsoft-word/",
      innerText: "Microsoft Word",
      title: "View all posts in Microsoft Word",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/migration/",
      innerText: "migration",
      title: "View all posts in migration",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mike-f-robbins/",
      innerText: "Mike F Robbins",
      title: "View all posts in Mike F Robbins",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mike-fal/",
      innerText: "Mike Fal",
      title: "View all posts in Mike Fal",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mike-oneill/",
      innerText: "Mike ONeill",
      title: "View all posts in Mike ONeill",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mike-pfeiffer/",
      innerText: "Mike Pfeiffer",
      title: "View all posts in Mike Pfeiffer",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mike-stiers/",
      innerText: "Mike Stiers",
      title: "View all posts in Mike Stiers",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/mini-scripting-games/",
      innerText: "Mini Scripting Games",
      title: "View all posts in Mini Scripting Games",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/mobile/",
      innerText: "Mobile",
      title: "View all posts in Mobile",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/module/",
      innerText: "module",
      title: "View all posts in module",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/module-browser/",
      innerText: "Module Browser",
      title: "View all posts in Module Browser",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/modules/",
      innerText: "modules",
      title: "View all posts in modules",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/monitoring/",
      innerText: "monitoring",
      title: "View all posts in monitoring",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/monitoring-and-logs/",
      innerText: "Monitoring and logs",
      title: "View all posts in Monitoring and logs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/msoms/",
      innerText: "MSOMS",
      title: "View all posts in MSOMS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/multimedia/",
      innerText: "multimedia",
      title: "View all posts in multimedia",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/nano-server/",
      innerText: "Nano Server",
      title: "View all posts in Nano Server",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/network-adapter/",
      innerText: "Network Adapter",
      title: "View all posts in Network Adapter",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/network-adapters/",
      innerText: "network adapters",
      title: "View all posts in network adapters",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/networking/",
      innerText: "networking",
      title: "View all posts in networking",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/nicholas-cain/",
      innerText: "Nicholas Cain",
      title: "View all posts in Nicholas Cain",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/nicolas-blank/",
      innerText: "Nicolas Blank",
      title: "View all posts in Nicolas Blank",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/niklas-goude/",
      innerText: "Niklas Goude",
      title: "View all posts in Niklas Goude",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/norman-drews/",
      innerText: "Norman Drews",
      title: "View all posts in Norman Drews",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/numbers/",
      innerText: "Numbers",
      title: "View all posts in Numbers",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/objects/",
      innerText: "Objects",
      title: "View all posts in Objects",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/odbc/",
      innerText: "ODBC",
      title: "View all posts in ODBC",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/office/",
      innerText: "Office",
      title: "View all posts in Office",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/office-365/",
      innerText: "Office 365",
      title: "View all posts in Office 365",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/office-365-api/",
      innerText: "Office 365 API",
      title: "View all posts in Office 365 API",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/office-365-sites/",
      innerText: "Office 365 Sites",
      title: "View all posts in Office 365 Sites",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/official-rules/",
      innerText: "official rules",
      title: "View all posts in official rules",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/oliver-lipkau/",
      innerText: "Oliver Lipkau",
      title: "View all posts in Oliver Lipkau",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/operating-system/",
      innerText: "operating system",
      title: "View all posts in operating system",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/oracle/",
      innerText: "Oracle",
      title: "View all posts in Oracle",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/other-directory-services/",
      innerText: "other Directory Services",
      title: "View all posts in other Directory Services",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ous/",
      innerText: "OUs",
      title: "View all posts in OUs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/out-gridview/",
      innerText: "Out-GridView",
      title: "View all posts in Out-GridView",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/output/",
      innerText: "Output",
      title: "View all posts in Output",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/packet-traces/",
      innerText: "packet traces",
      title: "View all posts in packet traces",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/page-files/",
      innerText: "page files",
      title: "View all posts in page files",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/parameter/",
      innerText: "parameter",
      title: "View all posts in parameter",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/partners/",
      innerText: "Partners",
      title: "View all posts in Partners",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/passwords/",
      innerText: "passwords",
      title: "View all posts in passwords",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/patrick-mercier/",
      innerText: "Patrick Mercier",
      title: "View all posts in Patrick Mercier",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/paul-greeley/",
      innerText: "Paul Greeley",
      title: "View all posts in Paul Greeley",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/paul-higinbotham/",
      innerText: "Paul Higinbotham",
      title: "View all posts in Paul Higinbotham",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/paulo-morgado/",
      innerText: "Paulo Morgado",
      title: "View all posts in Paulo Morgado",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/per-pedersen/",
      innerText: "Per Pedersen",
      title: "View all posts in Per Pedersen",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/performance/",
      innerText: "performance",
      title: "View all posts in performance",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/peripherals-and-devices/",
      innerText: "peripherals and devices",
      title: "View all posts in peripherals and devices",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/permission-and-security/",
      innerText: "permission and security",
      title: "View all posts in permission and security",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/permissions/",
      innerText: "permissions",
      title: "View all posts in permissions",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/pester/",
      innerText: "Pester",
      title: "View all posts in Pester",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/phil-braniff/",
      innerText: "Phil Braniff",
      title: "View all posts in Phil Braniff",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/pinvoke/",
      innerText: "pinvoke",
      title: "View all posts in pinvoke",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/pipeline/",
      innerText: "pipeline",
      title: "View all posts in pipeline",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/plain-text-logs/",
      innerText: "plain-text logs",
      title: "View all posts in plain-text logs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/podcast/",
      innerText: "Podcast",
      title: "View all posts in Podcast",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ports/",
      innerText: "ports",
      title: "View all posts in ports",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ports-and-slots/",
      innerText: "ports and slots",
      title: "View all posts in ports and slots",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/power-management/",
      innerText: "power management",
      title: "View all posts in power management",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powercli/",
      innerText: "PowerCLI",
      title: "View all posts in PowerCLI",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell/",
      innerText: "PowerShell",
      title: "View all posts in PowerShell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell-3/",
      innerText: "PowerShell 3",
      title: "View all posts in PowerShell 3",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell-3-0/",
      innerText: "powershell 3.0",
      title: "View all posts in powershell 3.0",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell-4/",
      innerText: "PowerShell 4",
      title: "View all posts in PowerShell 4",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell-4-0/",
      innerText: "PowerShell 4.0",
      title: "View all posts in PowerShell 4.0",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell-5/",
      innerText: "Powershell 5",
      title: "View all posts in Powershell 5",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell-5-0/",
      innerText: "PowerShell 5.0",
      title: "View all posts in PowerShell 5.0",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/powershell-best-practices/",
      innerText: "PowerShell best practices",
      title: "View all posts in PowerShell best practices",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell-gallery/",
      innerText: "PowerShell Gallery",
      title: "View all posts in PowerShell Gallery",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powershell-get/",
      innerText: "PowerShell Get",
      title: "View all posts in PowerShell Get",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/powertip/",
      innerText: "PowerTip",
      title: "View all posts in PowerTip",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/powertip-find-default-session-config-connection-in-powershell-summary-find-the-default-session-configuration-connection-in-windows-powershell-how-can-i-determine-what-default-session-configuration-2/",
      innerText:
        "PowerTip: Find Default Session Config Connection in PowerShell Summary: Find the default session configuration connection in Windows PowerShell. How can I determine what default session configuration",
      title:
        "View all posts in PowerTip: Find Default Session Config Connection in PowerShell Summary: Find the default session configuration connection in Windows PowerShell. How can I determine what default session configuration",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/print/",
      innerText: "Print",
      title: "View all posts in Print",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/print-servers/",
      innerText: "print servers",
      title: "View all posts in print servers",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/print-servers-print-queues-and-print-jobs/",
      innerText: "Print Servers Print Queues and print jobs",
      title: "View all posts in Print Servers Print Queues and print jobs",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/printer-ports-drivers-and-devices/",
      innerText: "printer ports drivers and devices",
      title: "View all posts in printer ports drivers and devices",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/printers/",
      innerText: "Printers",
      title: "View all posts in Printers",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/printing/",
      innerText: "printing",
      title: "View all posts in printing",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/prize-winners/",
      innerText: "prize winners",
      title: "View all posts in prize winners",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/process/",
      innerText: "Process",
      title: "View all posts in Process",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/processes/",
      innerText: "processes",
      title: "View all posts in processes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/procmon/",
      innerText: "Procmon",
      title: "View all posts in Procmon",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/profile/",
      innerText: "profile",
      title: "View all posts in profile",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/profiles/",
      innerText: "profiles",
      title: "View all posts in profiles",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/prompt/",
      innerText: "prompt",
      title: "View all posts in prompt",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/providers/",
      innerText: "Providers",
      title: "View all posts in Providers",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/psdrives/",
      innerText: "PSDrives",
      title: "View all posts in PSDrives",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/psgallery/",
      innerText: "PSGallery",
      title: "View all posts in PSGallery",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/psget/",
      innerText: "PSGet",
      title: "View all posts in PSGet",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/psproviders/",
      innerText: "PSProviders",
      title: "View all posts in PSProviders",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/psreadline/",
      innerText: "PSReadLine",
      title: "View all posts in PSReadLine",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/query-completion-server/",
      innerText: "query completion server",
      title: "View all posts in query completion server",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/queues/",
      innerText: "queues",
      title: "View all posts in queues",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/quick-hits-friday/",
      innerText: "Quick-Hits Friday",
      title: "View all posts in Quick-Hits Friday",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ragnar-harper/",
      innerText: "Ragnar Harper",
      title: "View all posts in Ragnar Harper",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/raimund-andree/",
      innerText: "Raimund Andree",
      title: "View all posts in Raimund Andree",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/rajesh-b-ravindranath/",
      innerText: "Rajesh B. Ravindranath",
      title: "View all posts in Rajesh B. Ravindranath",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/ravikanth/",
      innerText: "Ravikanth",
      title: "View all posts in Ravikanth",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/raymond-mitchell/",
      innerText: "Raymond Mitchell",
      title: "View all posts in Raymond Mitchell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/receiving-input/",
      innerText: "receiving input",
      title: "View all posts in receiving input",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/recycle-bin/",
      innerText: "Recycle Bin",
      title: "View all posts in Recycle Bin",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/reference/",
      innerText: "Reference",
      title: "View all posts in Reference",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/regex/",
      innerText: "RegEx",
      title: "View all posts in RegEx",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/registry/",
      innerText: "registry",
      title: "View all posts in registry",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/regular-expressions/",
      innerText: "regular expressions",
      title: "View all posts in regular expressions",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/reliability/",
      innerText: "reliability",
      title: "View all posts in reliability",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/remote/",
      innerText: "remote",
      title: "View all posts in remote",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/remote-endpoint/",
      innerText: "Remote Endpoint",
      title: "View all posts in Remote Endpoint",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/remoting/",
      innerText: "remoting",
      title: "View all posts in remoting",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/reporting/",
      innerText: "Reporting",
      title: "View all posts in Reporting",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/reports/",
      innerText: "Reports",
      title: "View all posts in Reports",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/resource-guide/",
      innerText: "Resource Guide",
      title: "View all posts in Resource Guide",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/resource-ownership/",
      innerText: "resource ownership",
      title: "View all posts in resource ownership",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/retrieving-input/",
      innerText: "retrieving input",
      title: "View all posts in retrieving input",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rhys-campbell/",
      innerText: "Rhys Campbell",
      title: "View all posts in Rhys Campbell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rich-prescott/",
      innerText: "Rich Prescott",
      title: "View all posts in Rich Prescott",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/richard-diphoorn/",
      innerText: "Richard Diphoorn",
      title: "View all posts in Richard Diphoorn",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/richard-siddaway/",
      innerText: "Richard Siddaway",
      title: "View all posts in Richard Siddaway",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rob-campbell/",
      innerText: "Rob Campbell",
      title: "View all posts in Rob Campbell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/robert-robelo/",
      innerText: "Robert Robelo",
      title: "View all posts in Robert Robelo",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rod-colledge/",
      innerText: "Rod Colledge",
      title: "View all posts in Rod Colledge",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rodc/",
      innerText: "RODC",
      title: "View all posts in RODC",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rohn-edwards/",
      innerText: "Rohn Edwards",
      title: "View all posts in Rohn Edwards",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rolf-masuch/",
      innerText: "Rolf Masuch",
      title: "View all posts in Rolf Masuch",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rpc/",
      innerText: "RPC",
      title: "View all posts in RPC",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rsat/",
      innerText: "RSAT",
      title: "View all posts in RSAT",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rss/",
      innerText: "RSS",
      title: "View all posts in RSS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rss-feeds/",
      innerText: "RSS feeds",
      title: "View all posts in RSS feeds",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/rudolf-vesely/",
      innerText: "Rudolf Vesely",
      title: "View all posts in Rudolf Vesely",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/running/",
      innerText: "running",
      title: "View all posts in running",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/running-scripts/",
      innerText: "running scripts",
      title: "View all posts in running scripts",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/runspace/",
      innerText: "Runspace",
      title: "View all posts in Runspace",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/san/",
      innerText: "SAN",
      title: "View all posts in SAN",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/schedule-tasks/",
      innerText: "Schedule Tasks",
      title: "View all posts in Schedule Tasks",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scheduled-task/",
      innerText: "scheduled task",
      title: "View all posts in scheduled task",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scheduled-tasks/",
      innerText: "scheduled tasks",
      title: "View all posts in scheduled tasks",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/schema/",
      innerText: "Schema",
      title: "View all posts in Schema",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scom-2007-r2/",
      innerText: "SCOM 2007 R2",
      title: "View all posts in SCOM 2007 R2",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scope/",
      innerText: "Scope",
      title: "View all posts in Scope",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scott-ge/",
      innerText: "Scott Ge",
      title: "View all posts in Scott Ge",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/script-analyzer/",
      innerText: "Script Analyzer",
      title: "View all posts in Script Analyzer",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/script-browser/",
      innerText: "Script Browser",
      title: "View all posts in Script Browser",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/script-center/",
      innerText: "Script Center",
      title: "View all posts in Script Center",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/script-repository/",
      innerText: "Script Repository",
      title: "View all posts in Script Repository",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/script-signing/",
      innerText: "script signing",
      title: "View all posts in script signing",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scripter/",
      innerText: "Scripter",
      title: "View all posts in Scripter",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/scripti9ng-techniques/",
      innerText: "Scripti9ng Techniques",
      title: "View all posts in Scripti9ng Techniques",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scripting/",
      innerText: "Scripting",
      title: "View all posts in Scripting",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scripting-games/",
      innerText: "Scripting Games",
      title: "View all posts in Scripting Games",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/scripting-games-2014/",
      innerText: "Scripting Games 2014",
      title: "View all posts in Scripting Games 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scripting-guy/",
      innerText: "Scripting Guy!",
      title: "View all posts in Scripting Guy!",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scripting-technique/",
      innerText: "Scripting Technique",
      title: "View all posts in Scripting Technique",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/scripting-techniques/",
      innerText: "scripting techniques",
      title: "View all posts in scripting techniques",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scripting-templates/",
      innerText: "scripting templates",
      title: "View all posts in scripting templates",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/scripting-wife/",
      innerText: "Scripting Wife",
      title: "View all posts in Scripting Wife",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sean-kearney/",
      innerText: "Sean Kearney",
      title: "View all posts in Sean Kearney",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sean-mccown/",
      innerText: "Sean McCown",
      title: "View all posts in Sean McCown",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/search/",
      innerText: "search",
      title: "View all posts in search",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/searching/",
      innerText: "Searching",
      title: "View all posts in Searching",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/searching-active-directory/",
      innerText: "searching Active Directory",
      title: "View all posts in searching Active Directory",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/security/",
      innerText: "security",
      title: "View all posts in security",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/select-string/",
      innerText: "Select-String",
      title: "View all posts in Select-String",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sending-email/",
      innerText: "sending email",
      title: "View all posts in sending email",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sending-mail/",
      innerText: "sending mail",
      title: "View all posts in sending mail",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/series/",
      innerText: "Series",
      title: "View all posts in Series",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/serkan-varoglu/",
      innerText: "Serkan Varoglu",
      title: "View all posts in Serkan Varoglu",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/server-applications/",
      innerText: "Server Applications",
      title: "View all posts in Server Applications",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/server-side-management/",
      innerText: "Server-Side Management",
      title: "View all posts in Server-Side Management",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/server-side-update/",
      innerText: "Server-Side update",
      title: "View all posts in Server-Side update",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/servers/",
      innerText: "servers",
      title: "View all posts in servers",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/servers-wsus/",
      innerText: "servers WSUS",
      title: "View all posts in servers WSUS",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/service-packs-and-hot-fixes/",
      innerText: "Service Packs and Hot Fixes",
      title: "View all posts in Service Packs and Hot Fixes",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/service-packs-and-hotfixes/",
      innerText: "service packs and hotfixes",
      title: "View all posts in service packs and hotfixes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/services/",
      innerText: "services",
      title: "View all posts in services",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sessions/",
      innerText: "sessions",
      title: "View all posts in sessions",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/seth-brandes/",
      innerText: "Seth Brandes",
      title: "View all posts in Seth Brandes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/shadow-copy/",
      innerText: "Shadow Copy",
      title: "View all posts in Shadow Copy",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/shane-hoey/",
      innerText: "Shane Hoey",
      title: "View all posts in Shane Hoey",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/shane-nelson/",
      innerText: "Shane Nelson",
      title: "View all posts in Shane Nelson",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/shannon-ma/",
      innerText: "Shannon Ma",
      title: "View all posts in Shannon Ma",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/shared-folders-and-mapped-drives/",
      innerText: "shared folders and mapped drives",
      title: "View all posts in shared folders and mapped drives",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sharepoint/",
      innerText: "SharePoint",
      title: "View all posts in SharePoint",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sharepoint-2010/",
      innerText: "SharePoint 2010",
      title: "View all posts in SharePoint 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sharepoint-online/",
      innerText: "SharePoint Online",
      title: "View all posts in SharePoint Online",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/shay-levy/",
      innerText: "Shay Levy",
      title: "View all posts in Shay Levy",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/shell-application/",
      innerText: "shell.application",
      title: "View all posts in shell.application",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sherif-talaat/",
      innerText: "Sherif Talaat",
      title: "View all posts in Sherif Talaat",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/shubert-somer/",
      innerText: "Shubert Somer",
      title: "View all posts in Shubert Somer",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/siegfried-jagott/",
      innerText: "Siegfried Jagott",
      title: "View all posts in Siegfried Jagott",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sites-and-subnets/",
      innerText: "sites and subnets",
      title: "View all posts in sites and subnets",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/skydrive/",
      innerText: "SkyDrive",
      title: "View all posts in SkyDrive",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/smtp-mail/",
      innerText: "SMTP Mail",
      title: "View all posts in SMTP Mail",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/snapshot-debugger/",
      innerText: "Snapshot Debugger",
      title: "View all posts in Snapshot Debugger",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/snippets/",
      innerText: "Snippets",
      title: "View all posts in Snippets",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/software/",
      innerText: "Software",
      title: "View all posts in Software",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/solutions/",
      innerText: "Solutions",
      title: "View all posts in Solutions",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sorting/",
      innerText: "sorting",
      title: "View all posts in sorting",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/special-folders/",
      innerText: "special folders",
      title: "View all posts in special folders",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/splatting/",
      innerText: "splatting",
      title: "View all posts in splatting",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sponsor/",
      innerText: "sponsor",
      title: "View all posts in sponsor",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sponsors/",
      innerText: "sponsors",
      title: "View all posts in sponsors",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sql/",
      innerText: "SQL",
      title: "View all posts in SQL",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sql-2012/",
      innerText: "SQL 2012",
      title: "View all posts in SQL 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sql-server/",
      innerText: "SQL Server",
      title: "View all posts in SQL Server",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sql-server-2008/",
      innerText: "SQL Server 2008",
      title: "View all posts in SQL Server 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sql-server-2012/",
      innerText: "SQL Server 2012",
      title: "View all posts in SQL Server 2012",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/startup-and-shutdown/",
      innerText: "startup and shutdown",
      title: "View all posts in startup and shutdown",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/stefan-roth/",
      innerText: "Stefan Roth",
      title: "View all posts in Stefan Roth",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/stefan-stranger/",
      innerText: "Stefan Stranger",
      title: "View all posts in Stefan Stranger",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/stephane-van-gulick/",
      innerText: "Stephane van Gulick",
      title: "View all posts in Stephane van Gulick",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/steve-jeffery/",
      innerText: "Steve Jeffery",
      title: "View all posts in Steve Jeffery",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/steve-murawski/",
      innerText: "Steve Murawski",
      title: "View all posts in Steve Murawski",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/steven-murawski/",
      innerText: "Steven Murawski",
      title: "View all posts in Steven Murawski",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/storage/",
      innerText: "storage",
      title: "View all posts in storage",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/streams/",
      innerText: "Streams",
      title: "View all posts in Streams",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/string/",
      innerText: "string",
      title: "View all posts in string",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/string-manipulation/",
      innerText: "string manipulation",
      title: "View all posts in string manipulation",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/strings/",
      innerText: "Strings",
      title: "View all posts in Strings",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/study-guide/",
      innerText: "study guide",
      title: "View all posts in study guide",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/susan-ferrell/",
      innerText: "Susan Ferrell",
      title: "View all posts in Susan Ferrell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/susan-hill/",
      innerText: "Susan Hill",
      title: "View all posts in Susan Hill",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/sysinternals/",
      innerText: "Sysinternals",
      title: "View all posts in Sysinternals",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/system-center/",
      innerText: "System Center",
      title: "View all posts in System Center",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/system-restore/",
      innerText: "System Restore",
      title: "View all posts in System Restore",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/taylor-gibb/",
      innerText: "Taylor Gibb",
      title: "View all posts in Taylor Gibb",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tcpip/",
      innerText: "TCP/IP",
      title: "View all posts in TCP/IP",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/teams/",
      innerText: "Teams",
      title: "View all posts in Teams",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/teched/",
      innerText: "TechEd",
      title: "View all posts in TechEd",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/teched-2014/",
      innerText: "TechEd 2014",
      title: "View all posts in TechEd 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/teched_2010/",
      innerText: "TechEd_2010",
      title: "View all posts in TechEd_2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/teched2012/",
      innerText: "TechEd2012",
      title: "View all posts in TechEd2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/teresa-wilson/",
      innerText: "Teresa Wilson",
      title: "View all posts in Teresa Wilson",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/terminal-server/",
      innerText: "Terminal Server",
      title: "View all posts in Terminal Server",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/terri-donahue/",
      innerText: "Terri Donahue",
      title: "View all posts in Terri Donahue",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/test-experience/",
      innerText: "Test Experience",
      title: "View all posts in Test Experience",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/testing/",
      innerText: "Testing",
      title: "View all posts in Testing",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/text/",
      innerText: "Text",
      title: "View all posts in Text",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/text-files/",
      innerText: "text files",
      title: "View all posts in text files",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/text-manipulation/",
      innerText: "Text manipulation",
      title: "View all posts in Text manipulation",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tfs/",
      innerText: "TFS",
      title: "View all posts in TFS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/the-mak/",
      innerText: "The MAK",
      title: "View all posts in The MAK",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/thiyagu/",
      innerText: "Thiyagu",
      title: "View all posts in Thiyagu",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/thomas-lee/",
      innerText: "Thomas Lee",
      title: "View all posts in Thomas Lee",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/thomas-rayner/",
      innerText: "Thomas Rayner",
      title: "View all posts in Thomas Rayner",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/thomas-stringer/",
      innerText: "Thomas Stringer",
      title: "View all posts in Thomas Stringer",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tibor-soos/",
      innerText: "Tibor Soos",
      title: "View all posts in Tibor Soos",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tim-bolton/",
      innerText: "Tim Bolton",
      title: "View all posts in Tim Bolton",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/time-sync/",
      innerText: "time sync",
      title: "View all posts in time sync",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/time-synchronization/",
      innerText: "Time Synchronization",
      title: "View all posts in Time Synchronization",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/timespan/",
      innerText: "TimeSpan",
      title: "View all posts in TimeSpan",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/timothy-warner/",
      innerText: "Timothy Warner",
      title: "View all posts in Timothy Warner",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tips-and-tricks/",
      innerText: "Tips and Tricks",
      title: "View all posts in Tips and Tricks",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tobias-weltner/",
      innerText: "Tobias Weltner",
      title: "View all posts in Tobias Weltner",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/todd-klindt/",
      innerText: "Todd Klindt",
      title: "View all posts in Todd Klindt",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tom-moser/",
      innerText: "Tom Moser",
      title: "View all posts in Tom Moser",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tom-shinder/",
      innerText: "Tom Shinder",
      title: "View all posts in Tom Shinder",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/tome-tanasovski/",
      innerText: "Tome Tanasovski",
      title: "View all posts in Tome Tanasovski",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/top-20/",
      innerText: "top 20",
      title: "View all posts in top 20",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/trace/",
      innerText: "trace",
      title: "View all posts in trace",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/traces/",
      innerText: "traces",
      title: "View all posts in traces",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/translation/",
      innerText: "translation",
      title: "View all posts in translation",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/trevor-sullivan/",
      innerText: "Trevor Sullivan",
      title: "View all posts in Trevor Sullivan",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/troubleshooting/",
      innerText: "troubleshooting",
      title: "View all posts in troubleshooting",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/types/",
      innerText: "Types",
      title: "View all posts in Types",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/updates/",
      innerText: "updates",
      title: "View all posts in updates",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/upgrade/",
      innerText: "Upgrade",
      title: "View all posts in Upgrade",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/usb/",
      innerText: "USB",
      title: "View all posts in USB",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/user-accounts/",
      innerText: "user accounts",
      title: "View all posts in user accounts",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/user-groups/",
      innerText: "user groups",
      title: "View all posts in user groups",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/users/",
      innerText: "users",
      title: "View all posts in users",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/using-the-internet/",
      innerText: "using the Internet",
      title: "View all posts in using the Internet",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/using-the-web/",
      innerText: "using the web",
      title: "View all posts in using the web",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/variable/",
      innerText: "Variable",
      title: "View all posts in Variable",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/variables/",
      innerText: "Variables",
      title: "View all posts in Variables",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/vb/",
      innerText: "VB",
      title: "View all posts in VB",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/vbscript/",
      innerText: "VBScript",
      title: "View all posts in VBScript",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/vbscript-migration/",
      innerText: "VBScript migration",
      title: "View all posts in VBScript migration",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/version/",
      innerText: "Version",
      title: "View all posts in Version",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/version-information/",
      innerText: "version information",
      title: "View all posts in version information",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/video/",
      innerText: "video",
      title: "View all posts in video",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/video-and-display/",
      innerText: "video and display",
      title: "View all posts in video and display",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/video-ram/",
      innerText: "video RAM",
      title: "View all posts in video RAM",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/videos/",
      innerText: "Videos",
      title: "View all posts in Videos",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/vinay-pamnani/",
      innerText: "Vinay Pamnani",
      title: "View all posts in Vinay Pamnani",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/virtual-machines/",
      innerText: "Virtual Machines",
      title: "View all posts in Virtual Machines",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/virtual-server/",
      innerText: "virtual server",
      title: "View all posts in virtual server",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/visual-studio/",
      innerText: "Visual Studio",
      title: "View all posts in Visual Studio",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/visual-studio-2017/",
      innerText: "Visual Studio 2017",
      title: "View all posts in Visual Studio 2017",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/visual-studio-app-center/",
      innerText: "Visual Studio App Center",
      title: "View all posts in Visual Studio App Center",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/visual-studio-code/",
      innerText: "Visual Studio Code",
      title: "View all posts in Visual Studio Code",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/visual-studio-for-mac/",
      innerText: "Visual Studio for Mac",
      title: "View all posts in Visual Studio for Mac",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/visual-studio-ide/",
      innerText: "Visual Studio IDE",
      title: "View all posts in Visual Studio IDE",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/visual-studio-live-share/",
      innerText: "Visual Studio Live Share",
      title: "View all posts in Visual Studio Live Share",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/visual-studio-marketplace/",
      innerText: "Visual Studio Marketplace",
      title: "View all posts in Visual Studio Marketplace",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/visual-studio-previews/",
      innerText: "Visual Studio Previews",
      title: "View all posts in Visual Studio Previews",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/visual-studio-subscriptions/",
      innerText: "Visual Studio Subscriptions",
      title: "View all posts in Visual Studio Subscriptions",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/visual-studio-tools-for-ai/",
      innerText: "Visual Studio Tools for AI",
      title: "View all posts in Visual Studio Tools for AI",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/visual-studio-tools-for-xamarin/",
      innerText: "Visual Studio Tools for Xamarin",
      title: "View all posts in Visual Studio Tools for Xamarin",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/vmm/",
      innerText: "VMM",
      title: "View all posts in VMM",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/walid-moselhy/",
      innerText: "Walid Moselhy",
      title: "View all posts in Walid Moselhy",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/web-pages-and-htas/",
      innerText: "Web pages and HTAs",
      title: "View all posts in Web pages and HTAs",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/web-service/",
      innerText: "Web Service",
      title: "View all posts in Web Service",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/web-services/",
      innerText: "web services",
      title: "View all posts in web services",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/webinar/",
      innerText: "Webinar",
      title: "View all posts in Webinar",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/websites/",
      innerText: "Websites",
      title: "View all posts in Websites",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/weekend-scripter/",
      innerText: "Weekend Scripter",
      title: "View all posts in Weekend Scripter",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/wei-hao-lim/",
      innerText: "Wei Hao Lim",
      title: "View all posts in Wei Hao Lim",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/will-anderson/",
      innerText: "Will Anderson",
      title: "View all posts in Will Anderson",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/will-martin/",
      innerText: "Will Martin",
      title: "View all posts in Will Martin",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/will-steele/",
      innerText: "Will Steele",
      title: "View all posts in Will Steele",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/william-stanek/",
      innerText: "William Stanek",
      title: "View all posts in William Stanek",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/win32/",
      innerText: "Win32",
      title: "View all posts in Win32",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-10/",
      innerText: "Windows 10",
      title: "View all posts in Windows 10",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-7/",
      innerText: "Windows 7",
      title: "View all posts in Windows 7",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-8/",
      innerText: "Windows 8",
      title: "View all posts in Windows 8",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-8-1/",
      innerText: "Windows 8.1",
      title: "View all posts in Windows 8.1",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-azure/",
      innerText: "Windows Azure",
      title: "View all posts in Windows Azure",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-explorer/",
      innerText: "Windows Explorer",
      title: "View all posts in Windows Explorer",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-management-framework/",
      innerText: "Windows Management Framework",
      title: "View all posts in Windows Management Framework",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-media-player-and-audio/",
      innerText: "Windows Media Player and audio",
      title: "View all posts in Windows Media Player and audio",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-nt/",
      innerText: "Windows NT",
      title: "View all posts in Windows NT",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-pe/",
      innerText: "Windows PE",
      title: "View all posts in Windows PE",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-powershell/",
      innerText: "Windows PowerShell",
      title: "View all posts in Windows PowerShell",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-powershell-3-0/",
      innerText: "Windows PowerShell 3.0",
      title: "View all posts in Windows PowerShell 3.0",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-powershell-4-0/",
      innerText: "Windows PowerShell 4.0",
      title: "View all posts in Windows PowerShell 4.0",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-powershell-5/",
      innerText: "Windows PowerShell 5",
      title: "View all posts in Windows PowerShell 5",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-powershell-5-0/",
      innerText: "Windows PowerShell 5.0",
      title: "View all posts in Windows PowerShell 5.0",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-powershell-ise/",
      innerText: "Windows PowerShell ISE",
      title: "View all posts in Windows PowerShell ISE",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-powershll/",
      innerText: "Windows PowerShll",
      title: "View all posts in Windows PowerShll",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-server/",
      innerText: "Windows Server",
      title: "View all posts in Windows Server",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-server-2003/",
      innerText: "Windows Server 2003",
      title: "View all posts in Windows Server 2003",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-server-2008-r2/",
      innerText: "Windows Server 2008 R2",
      title: "View all posts in Windows Server 2008 R2",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-server-2012/",
      innerText: "Windows Server 2012",
      title: "View all posts in Windows Server 2012",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-server-2012-r2/",
      innerText: "Windows Server 2012 R2",
      title: "View all posts in Windows Server 2012 R2",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-server-2016/",
      innerText: "Windows Server 2016",
      title: "View all posts in Windows Server 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-to-go/",
      innerText: "Windows To Go",
      title: "View all posts in Windows To Go",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-update/",
      innerText: "Windows Update",
      title: "View all posts in Windows Update",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/windows-vista/",
      innerText: "Windows Vista",
      title: "View all posts in Windows Vista",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/windows-windows-powershell/",
      innerText: "Windows Windows PowerShell",
      title: "View all posts in Windows Windows PowerShell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/winnt/",
      innerText: "WinNT",
      title: "View all posts in WinNT",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/winrm/",
      innerText: "WinRM",
      title: "View all posts in WinRM",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/wmi/",
      innerText: "WMI",
      title: "View all posts in WMI",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/word/",
      innerText: "Word",
      title: "View all posts in Word",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/word-application/",
      innerText: "Word.Application",
      title: "View all posts in Word.Application",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/workflow/",
      innerText: "Workflow",
      title: "View all posts in Workflow",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/workflows/",
      innerText: "Workflows",
      title: "View all posts in Workflows",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/wpf/",
      innerText: "WPF",
      title: "View all posts in WPF",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/wpk/",
      innerText: "WPK",
      title: "View all posts in WPK",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/wrap-up/",
      innerText: "wrap up",
      title: "View all posts in wrap up",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/writing/",
      innerText: "writing",
      title: "View all posts in writing",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/writing-scripts/",
      innerText: "writing scripts",
      title: "View all posts in writing scripts",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/wshshell/",
      innerText: "WshShell",
      title: "View all posts in WshShell",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/wsus/",
      innerText: "WSUS",
      title: "View all posts in WSUS",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/xamarin/",
      innerText: "Xamarin",
      title: "View all posts in Xamarin",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/xml/",
      innerText: "XML",
      title: "View all posts in XML",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/xpath/",
      innerText: "xpath",
      title: "View all posts in xpath",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/yan-pan/",
      innerText: "Yan Pan",
      title: "View all posts in Yan Pan",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/yuri-diogenes/",
      innerText: "Yuri Diogenes",
      title: "View all posts in Yuri Diogenes",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/tag/zachary-loeber/",
      innerText: "Zachary Loeber",
      title: "View all posts in Zachary Loeber",
    },
    {
      href:
        "https://devblogs.microsoft.com/scripting/tag/zune-and-other-media-devices/",
      innerText: "Zune and other media devices",
      title: "View all posts in Zune and other media devices",
    },
  ]
  const third_block = [
    {
      href: "https://devblogs.microsoft.com/scripting/2021/02/",
      innerText: "February 2021",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2020/09/",
      innerText: "September 2020",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2019/12/",
      innerText: "December 2019",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2019/11/",
      innerText: "November 2019",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2019/10/",
      innerText: "October 2019",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2019/09/",
      innerText: "September 2019",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2019/08/",
      innerText: "August 2019",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2019/07/",
      innerText: "July 2019",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/11/",
      innerText: "November 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/09/",
      innerText: "September 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/08/",
      innerText: "August 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/07/",
      innerText: "July 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/06/",
      innerText: "June 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/05/",
      innerText: "May 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/04/",
      innerText: "April 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/03/",
      innerText: "March 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/02/",
      innerText: "February 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2018/01/",
      innerText: "January 2018",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2017/12/",
      innerText: "December 2017",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2017/11/",
      innerText: "November 2017",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2017/10/",
      innerText: "October 2017",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2017/08/",
      innerText: "August 2017",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2017/07/",
      innerText: "July 2017",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2017/02/",
      innerText: "February 2017",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2017/01/",
      innerText: "January 2017",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/12/",
      innerText: "December 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/11/",
      innerText: "November 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/10/",
      innerText: "October 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/09/",
      innerText: "September 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/08/",
      innerText: "August 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/07/",
      innerText: "July 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/06/",
      innerText: "June 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/05/",
      innerText: "May 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/04/",
      innerText: "April 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/03/",
      innerText: "March 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/02/",
      innerText: "February 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2016/01/",
      innerText: "January 2016",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/12/",
      innerText: "December 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/11/",
      innerText: "November 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/10/",
      innerText: "October 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/09/",
      innerText: "September 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/08/",
      innerText: "August 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/07/",
      innerText: "July 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/06/",
      innerText: "June 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/05/",
      innerText: "May 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/04/",
      innerText: "April 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/03/",
      innerText: "March 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/02/",
      innerText: "February 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2015/01/",
      innerText: "January 2015",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/12/",
      innerText: "December 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/11/",
      innerText: "November 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/10/",
      innerText: "October 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/09/",
      innerText: "September 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/08/",
      innerText: "August 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/07/",
      innerText: "July 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/06/",
      innerText: "June 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/05/",
      innerText: "May 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/04/",
      innerText: "April 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/03/",
      innerText: "March 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/02/",
      innerText: "February 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2014/01/",
      innerText: "January 2014",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/12/",
      innerText: "December 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/11/",
      innerText: "November 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/10/",
      innerText: "October 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/09/",
      innerText: "September 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/08/",
      innerText: "August 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/07/",
      innerText: "July 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/06/",
      innerText: "June 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/05/",
      innerText: "May 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/04/",
      innerText: "April 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/03/",
      innerText: "March 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/02/",
      innerText: "February 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2013/01/",
      innerText: "January 2013",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/12/",
      innerText: "December 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/11/",
      innerText: "November 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/10/",
      innerText: "October 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/09/",
      innerText: "September 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/08/",
      innerText: "August 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/07/",
      innerText: "July 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/06/",
      innerText: "June 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/05/",
      innerText: "May 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/04/",
      innerText: "April 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/03/",
      innerText: "March 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/02/",
      innerText: "February 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2012/01/",
      innerText: "January 2012",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/12/",
      innerText: "December 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/11/",
      innerText: "November 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/10/",
      innerText: "October 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/09/",
      innerText: "September 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/08/",
      innerText: "August 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/07/",
      innerText: "July 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/06/",
      innerText: "June 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/05/",
      innerText: "May 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/04/",
      innerText: "April 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/03/",
      innerText: "March 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/02/",
      innerText: "February 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2011/01/",
      innerText: "January 2011",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/12/",
      innerText: "December 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/11/",
      innerText: "November 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/10/",
      innerText: "October 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/09/",
      innerText: "September 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/08/",
      innerText: "August 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/07/",
      innerText: "July 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/06/",
      innerText: "June 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/05/",
      innerText: "May 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/04/",
      innerText: "April 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/03/",
      innerText: "March 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/02/",
      innerText: "February 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2010/01/",
      innerText: "January 2010",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/12/",
      innerText: "December 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/11/",
      innerText: "November 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/10/",
      innerText: "October 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/09/",
      innerText: "September 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/08/",
      innerText: "August 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/07/",
      innerText: "July 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/06/",
      innerText: "June 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/05/",
      innerText: "May 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/04/",
      innerText: "April 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/03/",
      innerText: "March 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/02/",
      innerText: "February 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2009/01/",
      innerText: "January 2009",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/12/",
      innerText: "December 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/11/",
      innerText: "November 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/10/",
      innerText: "October 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/09/",
      innerText: "September 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/08/",
      innerText: "August 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/07/",
      innerText: "July 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/06/",
      innerText: "June 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/05/",
      innerText: "May 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/04/",
      innerText: "April 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/03/",
      innerText: "March 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/02/",
      innerText: "February 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2008/01/",
      innerText: "January 2008",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/12/",
      innerText: "December 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/11/",
      innerText: "November 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/10/",
      innerText: "October 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/09/",
      innerText: "September 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/08/",
      innerText: "August 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/07/",
      innerText: "July 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/06/",
      innerText: "June 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/05/",
      innerText: "May 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/04/",
      innerText: "April 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/03/",
      innerText: "March 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/02/",
      innerText: "February 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2007/01/",
      innerText: "January 2007",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/12/",
      innerText: "December 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/11/",
      innerText: "November 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/10/",
      innerText: "October 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/09/",
      innerText: "September 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/08/",
      innerText: "August 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/07/",
      innerText: "July 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/06/",
      innerText: "June 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/05/",
      innerText: "May 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/04/",
      innerText: "April 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/03/",
      innerText: "March 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/02/",
      innerText: "February 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2006/01/",
      innerText: "January 2006",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/12/",
      innerText: "December 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/11/",
      innerText: "November 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/10/",
      innerText: "October 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/09/",
      innerText: "September 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/08/",
      innerText: "August 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/07/",
      innerText: "July 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/06/",
      innerText: "June 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/05/",
      innerText: "May 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/04/",
      innerText: "April 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/03/",
      innerText: "March 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/02/",
      innerText: "February 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2005/01/",
      innerText: "January 2005",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2004/12/",
      innerText: "December 2004",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2004/11/",
      innerText: "November 2004",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2004/10/",
      innerText: "October 2004",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2004/09/",
      innerText: "September 2004",
    },
    {
      href: "https://devblogs.microsoft.com/scripting/2004/08/",
      innerText: "August 2004",
    },
  ]

  const Block = props => {
    return (
      <div
        style={{
          backgroundColor: "rgb(255,255,255)",
          width: "400px",
          height: "400px",
          padding: "20px",
        }}
      >
        <strong style={{ fontSize: "25px" }}>{props.heading}</strong>
        <div
          style={{
            height: "300px",
            overflow: "auto",
            margin: "10px",
            lineHeight: "1",
            fontSize: "20px",
          }}
        >
          {props.list.map((eachList, eachIndex) => {
            return (
              <p
                key={eachIndex}
                className={`${props.block === "two" && "btn btn-light btn-sm"}`}
                style={
                  props.block === "two"
                    ? { border: "1px solid", marginRight: "5px" }
                    : {}
                }
              >
                <a href={eachList.href} target='_blank' rel='noreferrer'>
                  {eachList.innerText}
                </a>
              </p>
            )
          })}
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        backgroundColor: "rgb(244,244,244)",
        height: "100vh",
        padding: "30px",
        paddingTop: "100px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Block heading='PowerShell Resources' list={first_block} block='one' />
      <Block heading='Topics' list={second_block} block='two' />
      <Block heading='Archive' list={third_block} block='three' />
    </div>
  )
}
export default Main
