import { useEffect, useState, useRef } from "react"

import "./style.css"
import EmojiIcon from "../../images/EmojiIcon.svg"
import AttachIcon from "../../images/AttachIcon.svg"
import MicIcon from "../../images/MicIcon.svg"
import { sendMessage } from "../../../../redux/actions/actions"
import { useDispatch, useSelector } from "react-redux"

const FooterComponent = props => {
  const dispatch = useDispatch()

  const automaticScrollDown = special_id => {
    const element = document.getElementById(special_id)
    element.scrollTop = element.scrollHeight
  }
  const [msg, setMsg] = useState("")
  const element = useRef(null)
  const data = useSelector(state => state.data)

  useEffect(() => {
    element.current.focus()
    setMsg("")
  }, [data])

  return (
    <div className='footer-component'>
      <div className='ist-block'>
        <img src={EmojiIcon} alt='Emoji Icon' />
        <img src={AttachIcon} alt='Attach Icon' />
      </div>
      <div className='second-block'>
        <input
          ref={element}
          value={msg}
          onChange={e => {
            if (msg === "" && e.target.value === " ") {
            } else {
              setMsg(e.target.value)
            }
          }}
          onKeyPress={e => {
            if (e.key === "Enter" && msg !== "") {
              //  within a component store the input value
              dispatch(sendMessage({ msg }))
              //  empty the current value from input element
              setMsg("")
              const special_id = "conversation-block"
              automaticScrollDown(special_id)
            }
          }}
          type='text'
          placeholder='Type a message'
          className='input-message'
        />
      </div>
      <div className='third-block'>
        <img src={MicIcon} alt='Voice Icon' />
      </div>
    </div>
  )
}
export default FooterComponent
