const routerPath = () => {
  const routes = {}
  routes["/My_Code_Reader/"] = require("./My_Code_Reader/").default
  routes["/accidental_developer/"] = require("./accidental_developer/").default
  routes["/Three_blocks/"] = require("./Three_blocks/").default
  routes[
    "/lets_see_behind_the_library/"
  ] = require("./lets_see_behind_the_library/").default
  routes[
    "/group_policy_administrative_template/"
  ] = require("./group_policy_administrative_template/").default
  routes["/my_whats_app/"] = require("./my_whats_app/").default
  routes[
    "/Improving_written_english/"
  ] = require("./Improving_written_english/").default
  routes["/my_css_classes/"] = require("./my_css_classes/").default
  routes["/react_resources/"] = require("./react_resources/").default
  routes["/My_Portfolio/"] = require("./My_Portfolio/").default
  routes["/footer_bar/"] = require("./footer_bar/").default
  routes["/side_bar_1/"] = require("./side_bar_1/").default
  routes[
    "/sarkari_result_naukri/"
  ] = require("./sarkari_result_naukri/").default
  return routes
}
export default routerPath
