import { Component } from "react"

class index extends Component {
  projectInfo() {
    return {
      projectTitle: "Improving Written English",
      projectDescription: "We can improve our written english.",
      referenceUrl: "https://vijaymathur948.github.io/english-typing-master/",
      createdAt: "15-02-2021",
      completedAt: "02-03-2021",
    }
  }
}

export default index
