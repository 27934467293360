import React, { Component } from "react"
import styled from "styled-components"
import AceEditor from "react-ace"

import { Button, Breadcrumb, Row, Col, Container } from "react-bootstrap"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/theme-monokai"

// Import all images in image folder
function importAll(r, key) {
  let images = {}
  r.keys().map((item, index) => {
    images[item] = r(item).default
  })

  return r(key).default
}
const p = "../../components/"
class MyCodeReader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      result: [],
      localResult: [],
      files: [],
      folders: [],
      path: ["My PortFolio"],
      absoluteUrl: "",
      fetchData: "",
    }
  }
  projectInfo() {
    return {
      projectTitle: "My Code Reader",
      projectDescription: "You can see the code.",
      referenceUrl: "",
      developer: "Vijay Mathur",
      createdAt: "16-04-2021",
      completedAt: "16-04-2021",
    }
  }
  componentDidMount() {
    console.log("log 1", this.getItems().keys())
    const keys = []
    this.getItems()
      .keys()
      .map(loc => {
        keys.push(loc.replace("./", "").split("/"))
      })
    this.setState({
      result: keys,
    })
    this.setState({
      localResult: keys,
    })
    console.log("main collection", keys)
    this.getElements(keys)
  }
  getChildItem = folderName => {
    var localResult = []
    this.state.localResult.map(arr => {
      if (arr[0] === folderName) localResult.push([...arr])
    })
    localResult.map(arr => {
      arr.shift()
    })

    this.setState({ localResult: localResult })
    this.getElements(localResult)

    //  setting a path so that we can come back
    this.setState({ path: [...this.state.path, folderName] })
  }

  getElements(collection) {
    console.log("collection", collection)
    const files = []
    const folders = []
    const local = []

    //  first time an array of array
    collection.map((nestedArray, nestedArrayIndex) => {
      if (nestedArray.length === 1) {
        files.push(nestedArray[0])
      } else {
        local.push(nestedArray)
      }
    })

    local.map(folderArray => {
      if (!folders.includes(folderArray[0])) folders.push(folderArray[0])
    })

    this.setState({ files: files })
    this.setState({ folders: folders })
  }

  getItems() {
    const pathProvider = require.context(
      "../../components/",
      true,
      /\.(ps1|js|jsx)$/
    )
    return pathProvider
  }
  goBack = async currentPathIndex => {
    const NO_OF_STEPS_BACK = this.state.path.length - (currentPathIndex + 1)
    console.log("steps back", NO_OF_STEPS_BACK)
    for (var j = 0; j < NO_OF_STEPS_BACK; j++) {
      await this.back()
    }
  }
  back = () => {
    var duplicatePath = [...this.state.path]
    duplicatePath.pop()

    //  if we are only one step back then just update the existing array as it is
    if (duplicatePath.length === 1) {
      this.setState({ localResult: this.state.result })
      this.getElements(this.state.result)
    } else {
      var duplicateResult = [...this.state.result]
      var resultedArray = []
      var nestedArray = []
      duplicateResult.map(arr => {
        nestedArray = [...arr]
        if (
          nestedArray.slice(0, duplicatePath.length - 1).toString() ===
          duplicatePath
            .filter(path => {
              if (path !== this.state.path[0]) return true
              else return false
            })
            .toString()
        ) {
          for (var i = 0; i < duplicatePath.length - 1; i++) {
            nestedArray.shift()
          }
          resultedArray.push([...nestedArray])
        }
      })
      this.setState({ localResult: resultedArray })
      this.getElements(resultedArray)
    }
    this.setState({ path: duplicatePath })
  }

  render() {
    return (
      <div className='p-3' style={{ backgroundColor: "currentcolor" }}>
        <Breadcrumb
          style={{ visibility: this.state.path.length ? "visible" : "hidden" }}
        >
          {this.state.path.map((path, pathIndex) => {
            return (
              <React.Fragment key={pathIndex}>
                <Breadcrumb.Item
                  onClick={
                    this.state.path.length - 1 === pathIndex
                      ? () => {}
                      : async () => {
                          await this.goBack(pathIndex)
                          console.log("previous", this.state.path)

                          this.setState({
                            absoluteUrl: this.state.path
                              .slice(1, this.state.path.length)
                              .toString()
                              .replaceAll(",", "/"),
                          })
                        }
                  }
                  active={this.state.path.length - 1 === pathIndex}
                >
                  {path}
                </Breadcrumb.Item>
              </React.Fragment>
            )
          })}
        </Breadcrumb>

        {this.state.folders && (
          <div>
            <div style={{ color: "white", fontSize: "30px" }}>
              Folders: <br /> <br />
            </div>
            <div>
              {this.state.folders.map((folder, folderIndex) => {
                return (
                  <Button
                    key={folderIndex}
                    className='p-3'
                    variant='secondary'
                    onClick={async () => {
                      await this.getChildItem(folder)
                      console.log("next", this.state.path)

                      this.setState({
                        absoluteUrl:
                          "./" +
                          this.state.path
                            .slice(1, this.state.path.length)
                            .toString()
                            .replaceAll(",", "/"),
                      })
                    }}
                  >
                    {folder}
                  </Button>
                )
              })}
            </div>
          </div>
        )}
        {this.state.files && (
          <div>
            <div style={{ color: "white", fontSize: "30px" }}>
              Files: <br /> <br />
            </div>
            <div>
              {this.state.files.map((file, fileIndex) => {
                return (
                  <Button
                    key={fileIndex}
                    className='p-2'
                    variant='outline-secondary'
                    onClick={async () => {
                      //                      var path = this.state.absoluteUrl.split("/")

                      if (!this.state.absoluteUrl.includes(file)) {
                        var path = this.state.absoluteUrl.split("/")
                        if (path[path.length - 1].includes("."))
                          path[path.length - 1] = file
                        else path.push(file)
                        path = path.join("/")

                        this.setState({
                          absoluteUrl:
                            this.state.absoluteUrl === "" ? "./" + file : path,
                        })
                      }
                    }}
                  >
                    {file}
                  </Button>
                )
              })}
            </div>
          </div>
        )}
        <Container fluid className='mb-3'>
          <Row className='text-white overflow-auto'>
            <h1>{this.state.absoluteUrl}</h1>
          </Row>
        </Container>
        <Row>
          <Col>
            <Button
              onClick={() => {
                if (this.state.absoluteUrl === "") {
                  this.setState({ fetchData: "" })
                } else {
                  this.setState({
                    fetchData: importAll(
                      this.getItems(),
                      this.state.absoluteUrl
                    ).toString(),
                  })
                }
              }}
            >
              Fetch File
            </Button>
          </Col>
        </Row>
        <AceEditor
          className='ml-4 p-2'
          style={{
            border: "5px solid black",
            margin: "10px ",
          }}
          value={this.state.fetchData}
          mode='javascript'
          theme='monokai'
          fontSize='25px'
          width='70%'
        />
      </div>
    )
  }
}

export default MyCodeReader
