import React, { Component } from "react"

class index extends Component {
  projectInfo() {
    return {
      projectTitle: "My CSS Classes",
      projectDescription: "In this project I am trying to understand CSS.",
      referenceUrl: "https://vijaymathur948.github.io/myCssClasses/",
      developer: "Vijay Mathur",
      createdAt: "10-03-2021",
      completedAt: "15-03-2021",
    }
  }
  render() {
    return <div></div>
  }
}

export default index
