import "./style.css"
import SearchIcon from "../../images/SearchIcon.svg"
import ThreeDots from "../../images/ThreeDots.svg"
import { useSelector } from "react-redux"

const HeaderComponent = () => {
  const data = useSelector(state => state.data)

  return (
    <div className='header-component'>
      <div className='profile-pic'>
        <img src={data && data.linkUrl} alt='' />
        <div className='user-name'>{data && data.name}</div>
      </div>
      <div className='right-side-icons'>
        <img src={SearchIcon} alt='Search Icon' />
        <img src={ThreeDots} alt='Three Dots' />
      </div>
    </div>
  )
}
export default HeaderComponent
