import "./style.css"
import HeaderComponent from "./HeaderComponent/HeaderComponent"
import FooterComponent from "./FooterComponent/FooterComponent"
import MiddleComponent from "./MiddleComponent/MiddleComponent"

const RightSideComponent = props => {
  return (
    <div className='flex-right-main'>
      <HeaderComponent />
      <MiddleComponent />
      <FooterComponent />
    </div>
  )
}
export default RightSideComponent
