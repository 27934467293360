import "./style.css"
import TableComponent from "../TableComponent/TableComponent"
import tableData from "../tableData"
const BodyComponent = () => {
  const threeLinks = [
    {
      imageUrl:
        "https://www.sarkariresultnaukri.com/wp-content/uploads/2019/04/UPHESC.jpg",
      linkName:
        "UPHESC Assistant Professor Recruitment 2021 – 2003 Assistant Professor Vacancy – Last Date 12 April",
      linkHref:
        "https://www.sarkariresultnaukri.com/uphesc-assistant-professor-vacancy-notification/",
    },
    {
      imageUrl:
        "https://www.sarkariresultnaukri.com/wp-content/uploads/2019/04/UPHESC.jpg",
      linkName:
        "UP Police ASI Recruitment 2021 – 1329 Assistant Sub Inspector (Clerk/Account) & Sub Inspector (Confidential) Vacancy – Last Date 31 May",
      linkHref:
        "https://www.sarkariresultnaukri.com/up-police-asi-vacancy-recruitment-2021/",
    },
    {
      imageUrl:
        "https://www.sarkariresultnaukri.com/wp-content/uploads/2019/04/UPHESC.jpg",
      linkName:
        "Bihar Police Fireman Recruitment 2021 – 2380 Constable (Fireman) Vacancy –",
      linkHref:
        "https://www.sarkariresultnaukri.com/bihar-police-fireman-vacancy-notification/",
    },
  ]

  return (
    <div className='bodyComponent'>
      <div className='three-links'>
        {threeLinks.map((linksObj, linksIndex) => {
          return (
            <div className='content' key={linksIndex}>
              <div className='inner-content'>
                <a href={linksObj.linkHref} target='_blank' rel='noreferrer'>
                  <img src={linksObj.imageUrl} alt={linksObj.linkName} />
                </a>
                <h4 className='link-heading'>
                  <a href={linksObj.linkHref} target='_blank' rel='noreferrer'>
                    {linksObj.linkName}
                  </a>
                </h4>
              </div>
            </div>
          )
        })}
      </div>
      <div className='clearfix' />
      <h4 className='heading'>
        Free Job Alert 2020 – WhatsApp,Telegram Email FreeJobalert 2021
      </h4>
      <div className='description'>
        <h5>Register Free Job Alert 2020</h5>
        <p>
          Now you can join our whats group for daily government jobs, exam and
          interview result, admit card download notification, answer key, exam
          syllabus and all other information helpful for you exam preparation.
          We are creating many whatsapp group and we request you to join only
          one group because we cant add more then 256 member in whatsapp group.
          We also have telegram and other social media pages for latest updates.
          Your Can also join us on other social media groups like facebook,
          twitter, twitter, quora etc.
        </p>
      </div>
      {tableData.map((eachTableData, eachTableIndex) => (
        <TableComponent
          tableData={eachTableData}
          key={eachTableIndex}
          isLastTable={tableData.length - 1 === eachTableIndex ? true : false}
        />
      ))}
      <div className='last-content'>
        <p>
          List of our all Full Whatsapp Group ( Please Join Only One Group, All
          Have Same Updates
        </p>
      </div>
    </div>
  )
}
export default BodyComponent
