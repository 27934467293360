import React, { Component } from "react"
import styled from "styled-components"
import {
  Navbar,
  Nav,
  Jumbotron,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap"
import style from "./style.module.css"
import ParticlesBg from "particles-bg"
import { FaGithub, FaPhoneAlt, FaWhatsapp } from "react-icons/fa"
import { SiGmail } from "react-icons/si"
import { CopyToClipboard } from "react-copy-to-clipboard"

const github_account = "https://github.com/vijaymathur948"
const gmail_account = "vijaymathur2020.2025@gmail.com"
const mobile_no = "+919149695316"
const whatsapp_link = "https://wa.link/29guyx"

class MyPortfolio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      currentType: "color",
      types: [
        "color",
        "ball",
        "lines",
        "thick",
        "circle",
        "cobweb",
        "polygon",
        "square",
        "tadpole",
        "fountain",
        "random",
        "list",
        "custom",
      ],
      value: false,
      icons: [
        {
          tooltip_text: "Gmail Account",
          actual_url: gmail_account,
          icon: props => <SiGmail {...props} />,
        },
        {
          tooltip_text: "Mobile Number ",
          actual_url: mobile_no,
          icon: props => <FaPhoneAlt {...props} />,
        },
        {
          tooltip_text: "Github Account",
          actual_url: github_account,
          icon: props => <FaGithub {...props} />,
        },
        {
          tooltip_text: "WhatsApp Link",
          actual_url: whatsapp_link,
          icon: props => <FaWhatsapp {...props} />,
        },
      ],
    }
  }

  projectInfo() {
    return {
      projectTitle: "My Portfolio",
      projectDescription: "",
      referenceUrl: "",
      developer: "Vijay Mathur",
      createdAt: "13-06-2021",
      completedAt: "25-06-2021",
    }
  }
  startTimer = () => {
    setInterval(() => {
      console.log("hey")
      var index = this.state.index + 1
      if (index === this.state.types.length) {
        index = 0
      }
      this.setState({ currentType: this.state.types[index] })
      this.setState({ index: index })
    }, 3000)
  }
  componentDidMount() {
    //  this.startTimer()
  }
  render() {
    const links = {
      home: "#home",
      projects: "/",
      contact: "#contact",
      about: "#about",
    }
    const Header = () => {
      return (
        <Navbar
          fixed='top'
          style={{
            backgroundColor: "white",
            fontWeight: "bold",
          }}
        >
          <Nav className='m-auto'>
            <Nav.Link
              href={links.home}
              className={
                document.location.hash === links.home ? style.underline : {}
              }
            >
              HOME
            </Nav.Link>
            <Nav.Link
              href={links.projects}
              target='_blank'
              className={
                document.location.hash === links.projects ? style.underline : {}
              }
            >
              PROJECTS
            </Nav.Link>
            <Nav.Link
              href={links.contact}
              className={
                document.location.hash === links.contact ? style.underline : {}
              }
            >
              CONTACT
            </Nav.Link>
            <Nav.Link
              href={links.about}
              className={
                document.location.hash === links.about ? style.underline : {}
              }
            >
              ABOUT
            </Nav.Link>
          </Nav>
        </Navbar>
      )
    }

    return (
      <div
        className='portfolio'
        style={{
          fontFamily: "cursive",
          backgroundColor: "rgb(51,51,51)",
          textAlign: "center",
          color: "white",
        }}
      >
        {/* <ParticlesBg type={this.state.currentType} bg={true} /> */}

        {/* <Header />
         */}

        <div>
          <Jumbotron
            style={{
              margin: "50px",
              padding: "40px 200px",
              background: "transparent",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
              }}
            >
              Hello, My name is Vijay Mathur.
            </h1>
            <h3 style={{ lineHeight: "1.5" }}>
              I am a <b>MERN STACK DEVELOPER</b>. I develop applications using
              React JS, Node JS and Mongodb. Feel free to contact me.
            </h3>
          </Jumbotron>
          <h1>Contact Me</h1>
          <div className='d-flex' style={{ margin: "0px 40%" }}>
            {this.state.icons.map((obj, index) => {
              var Element = obj.icon
              return (
                <OverlayTrigger
                  key={index}
                  placement='top'
                  overlay={
                    <Tooltip id={`top_${index}`}>
                      {this.state.value ? (
                        <span
                          style={{
                            color: "yellow",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span className='text-center'>
                          Click to copy {obj.tooltip_text}
                        </span>
                      )}
                    </Tooltip>
                  }
                >
                  <CopyToClipboard
                    text={obj.actual_url}
                    onCopy={() => {
                      //  we have clicked so changed the value
                      this.setState({ value: true })
                      //  reset the value
                      setTimeout(() => {
                        this.setState({ value: false })
                      }, 1000)
                    }}
                  >
                    <Element
                      style={{ cursor: "pointer" }}
                      className='m-3'
                      fontSize='50px'
                      color='white'
                    />
                  </CopyToClipboard>
                </OverlayTrigger>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default MyPortfolio
