import React, { Component } from "react"
import logo from "../logo.svg"
import { Card, Navbar, Row, Badge } from "react-bootstrap"
import { Link } from "react-router-dom"
import routerPath from "./routerPath"

const routesList = routerPath()

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reduxComponents: ["/my_whats_app/"],
      blockedComponents: ["/My_Portfolio/"],
    }
  }

  openAll() {
    Object.keys(routesList).map((key, index) => window.open(key, key))
  }

  render() {
    var sortedRoutes = []

    Object.keys(routesList).map(key => {
      //  if we want to block some components then we can do that.
      if (!this.state.blockedComponents.includes(key)) {
        var dateString
        if (this.state.reduxComponents.includes(key)) {
          dateString = routesList[key].WrappedComponent.prototype
            .projectInfo()
            .createdAt.split("-")
        } else {
          dateString = routesList[key].prototype
            .projectInfo()
            .createdAt.split("-")
        }
        // date should be in this format eg: 28-01-2021
        var dateObject = new Date()
        dateObject.setFullYear(
          dateString[2], // representing year
          dateString[1], // representing month
          dateString[0] // representing date
        )
        var obj = {
          path: key,
          value: dateObject,
        }

        sortedRoutes.push(obj)
        return 0
      }
    })
    sortedRoutes.sort((a, b) => {
      if (a.value < b.value) return 1
      if (a.value > b.value) return -1
      return 0
    })

    return (
      <div>
        <Navbar
          bg='dark'
          variant='dark'
          style={{ justifyContent: "space-between" }}
        >
          <Navbar.Brand>
            <img alt='' src={logo} width='30' height='30' />
            MY REACT BASED SAMPLE PROJECTS
          </Navbar.Brand>

          <div>
            <a
              href='https://meet.google.com/pze-vecs-ozh'
              target='_blank'
              className='btn btn-outline-light mr-5'
              rel='noreferrer'
            >
              GOOGLE MEET
            </a>

            <Link
              to={this.state.blockedComponents[0]}
              target='_blank'
              className='btn btn-outline-light mr-5'
            >
              CONTACT ME
            </Link>
          </div>
        </Navbar>
        <Row className='m-0'>
          {sortedRoutes.map((object, index) => {
            var projectInfo = {}
            if (this.state.reduxComponents.includes(object.path)) {
              projectInfo =
                routesList[object.path].WrappedComponent.prototype.projectInfo()
            } else {
              projectInfo = routesList[object.path]?.prototype?.projectInfo()
            }
            return (
              <Card
                bg='dark'
                text='white'
                className='text-center m-4 '
                key={index}
              >
                <Card.Body>
                  <h1>
                    <Badge variant='success'>{index + 1}</Badge>
                  </h1>
                  <Card.Title
                    style={{ fontSize: "20px", width: "350px", height: "48px" }}
                  >
                    {projectInfo.projectTitle}
                    {/* 's Projects */}
                  </Card.Title>
                  <Card.Text style={{ fontSize: "15px", width: "340px" }}>
                    {projectInfo.projectDescription}
                    <br />
                    <b>
                      <big>Created At :{" " + projectInfo.createdAt}</big>
                    </b>
                    <br />
                    <b>
                      <big>Completed At :{" " + projectInfo.completedAt}</big>
                    </b>
                    <br />
                  </Card.Text>
                  <div style={{ display: "flex" }}>
                    {!projectInfo.referenceUrl.includes("github.io") && (
                      <Link
                        to={object.path}
                        target='_blank'
                        className='btn btn-outline-light mr-3'
                      >
                        Sample Component
                      </Link>
                    )}
                    <a
                      href={projectInfo.referenceUrl}
                      target='_blank'
                      rel='noreferrer'
                      className={`btn btn-outline-light ${
                        projectInfo.referenceUrl.includes("github.io")
                          ? "btn-block"
                          : ""
                      }`}
                    >
                      Original Component
                    </a>
                  </div>
                </Card.Body>
              </Card>
            )
          })}
        </Row>
      </div>
    )
  }
}

export default Main
