import React, { Component } from "react"
import styled from "styled-components"
import ThreeBlocks from "./ThreeBlocks"

class index extends Component {
  projectInfo() {
    return {
      projectTitle: "Three small Block",
      projectDescription: "The power of automation",
      referenceUrl:
        "https://devblogs.microsoft.com/scripting/powertip-use-powershell-to-show-remaining-battery-time/",
      developer: "Vijay Mathur",
      createdAt: "13-04-2021",
      completedAt: "13-04-2021",
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "rgb(230,238,244)" }}>
        <ThreeBlocks />
      </div>
    )
  }
}

export default index
