import React from "react"
import "./style.css"
import image from "./assets/rootImage.jpg"
const Index = () => {
  var links = [
    {
      linkName: "Sarkari Naukri",
      linkHref: "https://www.sarkariresultnaukri.com/",
    },
    {
      linkName: "Govt Jobs",
      linkHref: "https://www.sarkariresultnaukri.com/govt-jobs-india/",
    },
    {
      linkName: "Admit Card",
      linkHref:
        "https://www.sarkariresultnaukri.com/post-type/download-admit-card/",
    },
    {
      linkName: "Results",
      linkHref: "https://www.sarkariresultnaukri.com/post-type/exam-result/",
    },
    {
      linkName: "Answer Key",
      linkHref: "https://www.sarkariresultnaukri.com/post-type/answer-key/",
    },
    {
      linkName: "Current Affairs",
      linkHref:
        "https://www.sarkariresultnaukri.com/post-type/current-affairs/",
    },
    {
      linkName: "Education",
      linkHref:
        "https://www.sarkariresultnaukri.com/free-job-alert-registration/#Education",
    },
    {
      linkName: "StateWise",
      linkHref:
        "https://www.sarkariresultnaukri.com/free-job-alert-registration/#State",
    },
    {
      linkName: "JobRole",
      linkHref:
        "https://www.sarkariresultnaukri.com/free-job-alert-registration/#JobRole",
    },
  ]

  return (
    <div id='header'>
      <div className='links'>
        <ul>
          <li id='main-link'>
            <a href={document.location.pathname}>
              <img src={image} alt='' />
            </a>
          </li>
          {links.map((linksName, linksIndex) => {
            return (
              <li key={linksIndex} className='hide'>
                <a href={linksName.linkHref} target='_blank' rel='noreferrer'>
                  {linksName.linkName}
                </a>
              </li>
            )
          })}
          <li className='menu-bar'>
            <select
              name='menu-bar'
              id='menu-bar'
              onChange={e => {
                const value = e.target.value
                if (value !== "Menu") {
                  document.location.assign(e.target.value)
                }
              }}
            >
              <option>Menu</option>
              {links.map((linksName, linksIndex) => {
                return (
                  <option key={linksIndex} value={linksName.linkHref}>
                    {linksName.linkName}
                  </option>
                )
              })}
            </select>
          </li>
        </ul>
        <div className='clearfix'></div>
      </div>
    </div>
  )
}

export default Index
