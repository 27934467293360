import React from "react"
import "./style.css"

const FooterComponent = () => {
  const secondLastSectionLinks = [
    "https://chat.whatsapp.com/HYGrxiGiuZj6a7eZljIvMe",
    "https://chat.whatsapp.com/HOnYUZKg2R59DDfBJeIcSH",
    "https://chat.whatsapp.com/J2kZXEYB3Z07b4bwP99bv2",
    "https://chat.whatsapp.com/JtjBKWKBwvK2yn59fKoDx3",
    "https://chat.whatsapp.com/F0PJ8gQqpzPLPXvQfAURj9",
    "https://chat.whatsapp.com/Ct8sXu6fy3o7LyIQGXylGY",
    "https://chat.whatsapp.com/FMwvwYv5Ea19BinX7mm7PE",
    "https://chat.whatsapp.com/JxBribLAwfo2oBEvLkUylx",
    "https://chat.whatsapp.com/CKEBzkrc9097ExDgovh8qR",
    "https://chat.whatsapp.com/LmGRgWsvlaJBeeUDiquyiL",
    "https://chat.whatsapp.com/JCKTg9AkkeHE0kKWM4cAyP",
    "https://chat.whatsapp.com/EUKxumALkrJ68K4MZViOAq",
    "https://chat.whatsapp.com/HSDlAVUfMtwCyojmGeeRbz",
    "https://chat.whatsapp.com/LmYjLRH5ez1260GoVbICxM",
    "https://chat.whatsapp.com/KNvDRHXYsCEGL4tWOkFlak",
    "https://chat.whatsapp.com/GmEvfIXoURKB9YUjEcml2l",
    "https://chat.whatsapp.com/EuXqkS3aNuw274UnGR1xLh",
    "https://chat.whatsapp.com/Ea3CLtEmD4v91B0DGT6mCV",
    "https://chat.whatsapp.com/GfFWLYwV7Hg8RW0ll2Oghv",
    "https://chat.whatsapp.com/D8KdAtm9s9YKstzzDBnaCR",
    "https://chat.whatsapp.com/HUhtTXyABOpFSziTGupbIH",
    "https://chat.whatsapp.com/KOz6sh5aCwwEb0ZXmYy4wI",
    "https://chat.whatsapp.com/JlPk1EJKKR942oY6wqMNLJ",
    "https://chat.whatsapp.com/LyT94ubu6zF3a5Vo16mmyK",
    "https://chat.whatsapp.com/HxDKCir5uaX8O9wHXvsHdP",
    "https://chat.whatsapp.com/CY5YAOxuVTa0sdirFHtRkd",
    "https://chat.whatsapp.com/G3N5amt4XuP7cxlpsGOQ96",
    "https://chat.whatsapp.com/CRmcAJ74ExB2i39Xbgfq94",
    "https://chat.whatsapp.com/BQbBu7dF2fhIV81ztUr37P",
    "https://chat.whatsapp.com/Lu3GaO1kRHy08ozbhd72v5",
    "https://chat.whatsapp.com/LppRH0fHLRp2cSNWInPrRZ",
  ]
  const links = [
    {
      linkName: "Sarkari Naukri",
      linkHref: "https://www.sarkariresultnaukri.com/",
    },
    {
      linkName: " Govt Jobs",
      linkHref: "https://www.sarkariresultnaukri.com/govt-jobs-india/",
    },
    {
      linkName: " SarkariResult",
      linkHref: "https://www.sarkariresultnaukri.com/",
    },
    {
      linkName: "Free Job Alert",
      linkHref:
        "https://www.sarkariresultnaukri.com/free-job-alert-registration/",
    },
    { linkName: " shabd.in", linkHref: "https://shabd.in/" },
    {
      linkName: " Privacy Policy",
      linkHref: "https://www.sarkariresultnaukri.com/privacy-policy/",
    },
  ]
  return (
    <div>
      <div className='second-last-section'>
        {secondLastSectionLinks.map((eachWhatsAppGroupHref, eachGroupIndex) => {
          return (
            <div key={eachGroupIndex} className='whatsapp-groups'>
              <a href={eachWhatsAppGroupHref} target='_blank' rel='noreferrer'>
                Group {eachGroupIndex + 1}
              </a>
              |
            </div>
          )
        })}
        <hr /> <hr className='horizontal-line' />
      </div>
      <div className='footer'>
        <div className='footer-left'>
          <div className='footer-left-content'>
            <p>Copyright © 2021 - </p>
            {links.map((eachLinkObject, eachLinkObjectIndex) => {
              return (
                <div key={eachLinkObjectIndex}>
                  <a
                    href={eachLinkObject.linkHref}
                    className='each-link'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {eachLinkObject.linkName}
                  </a>
                  |
                </div>
              )
            })}
          </div>
        </div>
        <div className='footer-right'>
          <div className='footer-right-content'>
            <a
              href='https://www.dmca.com/Protection/Status.aspx?ID=89785741-8cd4-4dd4-b40d-8627f1d1da06&refurl=https://www.sarkariresultnaukri.com/free-job-alert-registration/#Education'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='//images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=89785741-8cd4-4dd4-b40d-8627f1d1da06'
                alt='DMCA.com Protection Status'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FooterComponent
