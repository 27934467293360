import "./style.css"
import DefaultProfileIcon from "../images/DefaultPageIcon.svg"

const DefaultPageComponent = () => {
  const defaultImageUrl =
    "https://web.whatsapp.com/img/intro-connection-light_c98cc75f2aa905314d74375a975d2cf2.jpg"

  return (
    <div className='flex-right'>
      <div className='default-content'>
        <div className='main-image'>
          <img src={defaultImageUrl} alt='' />
        </div>
        <h1>Keep your phone connected</h1>
        <div className='short-description'>
          <p>
            WhatsApp connects to your phone to sync messages. To reduce data
            usage, connect your phone to Wi-Fi.
          </p>
        </div>
        <hr className='divider-line' />
        <div className='description'>
          <span>
            <img src={DefaultProfileIcon} alt='' />
          </span>
          &nbsp;
          <div>
            WhatsApp is available for Windows. &nbsp;
            <a
              href='https://www.whatsapp.com/download'
              target='_blank'
              rel='noreferrer'
            >
              Get it here.
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DefaultPageComponent
