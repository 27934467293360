import actionConstant from "../actionConstants"

export const initialize = payload => {
  return {
    type: actionConstant.initialize,
    payload,
  }
}
export const userSelected = payload => {
  return {
    type: actionConstant.userSelected,
    payload,
  }
}
export const sendMessage = payload => {
  return {
    type: actionConstant.sendMessage,
    payload,
  }
}
