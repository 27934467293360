import React from "react"
import "./style.css"
import { useSelector } from "react-redux"

const MiddleComponent = props => {
  const messages = useSelector(state => state.data.users[state.data.name]) || []

  return (
    <div className='middle-block' id='conversation-block'>
      <div className='middle-part'>
        {messages &&
          messages.map((eachMessage, messageIndex) => {
            const timeStamp =
              eachMessage.timeStamp || new Date().toLocaleTimeString()
            return (
              <p
                key={messageIndex}
                className={`${
                  messageIndex % 2 === 1 ? "left-side" : "right-side"
                } `}
              >
                {eachMessage.msg}
                <span className='time'>{timeStamp}</span>
              </p>
            )
          })}
      </div>
    </div>
  )
}
export default MiddleComponent
