import React, { Component } from "react"
import Header from "./HeaderComponent/Header"
import BreadCrumb from "./BreadCrumb/BreadCrumb"
import BodyComponent from "./BodyComponent/BodyComponent"
import FooterComponent from "./FooterComponent/FooterComponent"

class index extends Component {
  projectInfo() {
    return {
      projectTitle: "A Page for Sarkari Result Jobs",
      projectDescription: "A page where can post notifications of govt jobs",
      referenceUrl:
        "https://www.sarkariresultnaukri.com/free-job-alert-registration/",
      developer: "Vijay Mathur",
      createdAt: "24-03-2021",
      completedAt: "29-03-2021",
    }
  }

  componentDidMount() {
    window.onresize = function () {
      var height = document.getElementById("header").clientHeight
      var tag = document.getElementsByClassName("breadcrumb")[0]
      var exactDistance = height + 20 + "px"
      if (exactDistance !== tag.style.marginTop) {
        tag.style.marginTop = exactDistance
      }
    }
  }

  render() {
    return (
      <div>
        <Header />
        <BreadCrumb />
        <BodyComponent />
        <FooterComponent />
      </div>
    )
  }
}

export default index
