import actionConstant from "../actionConstants"

const reducer = (state, action) => {
  switch (action.type) {
    // we have to create an empty array in users object
    //  so that we can send messages to each other
    case actionConstant.initialize:
      var data = { ...state.data }
      data.emailsList.map(individualEmail => {
        data.users[individualEmail] = []
      })
      return { ...state, data }
    case actionConstant.userSelected:
      const { userName: name, randomImageUrl: linkUrl } = action.payload
      const clicked = true
      state = { ...state, clicked }
      state.data = { ...state.data, name, linkUrl }
      return { ...state }

    case actionConstant.sendMessage:
      const { msg } = action.payload
      state.data.users[state.data.name].push({
        msg: msg,
        timeStamp: new Date().toLocaleTimeString(),
        currentUser: true ? "right-side" : "left-side",
      })
      return { ...state }

    default:
      return state
  }
}
export default reducer
