const TableData = [
  {
    heading: "Register For Free Job Alert 2020",
    headingBgColor: "bg1",
    headingAlignment: "center",
    rowsAlignment: "left",
    data: [
      [
        { text: "Register for Email Alert" },
        {
          link: [
            {
              linkName: "Click Here",
              linkUrl:
                "https://feedburner.google.com/fb/a/mailverify?uri=sarkari-naukri-daily",
            },
          ],
        },
      ],
      [
        { text: "Whatsapp Group Job Alert ( Join Only One Group)" },
        {
          link: [
            {
              linkName: "Group 32",
              linkUrl: "https://chat.whatsapp.com/GpVFF0nG3oVK9rBTSEWu5U",
            },
            {
              linkName: "Group 15",
              linkUrl: "https://chat.whatsapp.com/KNvDRHXYsCEGL4tWOkFlak",
            },
            {
              linkName: "Group 16",
              linkUrl: "https://chat.whatsapp.com/GmEvfIXoURKB9YUjEcml2l",
            },
            {
              linkName: "Group 14",
              linkUrl: "https://chat.whatsapp.com/LmYjLRH5ez1260GoVbICxM",
            },
          ],
        },
      ],
      [
        { text: "Telegram Group FreeJobAlert" },
        {
          link: [
            {
              linkName: "Click Here",
              linkUrl: "https://t.me/sarkariresultnaukri",
            },
          ],
        },
      ],
      [
        { text: "Facebook Group Job Alert" },
        {
          link: [
            {
              linkName: "Click Here",
              linkUrl: "https://www.facebook.com/groups/ResultNaukri",
            },
          ],
        },
      ],
      [
        { text: "Follow on InstaGram" },
        {
          link: [
            {
              linkName: "Click Here",
              linkUrl: "https://www.instagram.com/sarkariresultnaukri/",
            },
          ],
        },
      ],
      [
        { text: "Follow on Twitter" },
        {
          link: [
            {
              linkName: "Click Here",
              linkUrl: "https://twitter.com/ResultNaukri",
            },
          ],
        },
      ],
      [
        { text: "Quora Page Job Alert" },
        {
          link: [
            {
              linkName: "Click Here",
              linkUrl: "https://www.quora.com/q/sarkariresultnaukri",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "Free Job Alert by Qualification",
    headingBgColor: "bg2",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "8th",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/8th/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "10th",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/10th/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "10+2",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/12th/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "B Com",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/b-com/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "B Ed",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/b-ed/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "B Pharma",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/b-pharma/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "B Sc",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/b-sc/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "BBA",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/bba/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "BCA",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/bca/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "BDS",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/bds/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "BE B Tech",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/be-b-tech/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "CA ICWA",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/ca/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "CS ICSA",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/cs-icsa/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Diploma",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/diploma/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Graduate",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/graduate/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "ITI",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/iti/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "LLB",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/llb/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "LLM",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/llm/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "M Com",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/m-com/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "M Sc",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/m-sc/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "MBA",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/mba/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "MBBS",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/mbbs/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "MCA",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/mca/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "ME M Tech",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/me-m-tech/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "MSW",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/msw/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "M Phil",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/m-phil/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Ph D",
              linkUrl: "https://www.sarkariresultnaukri.com/qualification/phd/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "",
              linkUrl: "",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Post Graduate",
              linkUrl:
                "https://www.sarkariresultnaukri.com/qualification/post-graduate/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "",
              linkUrl: "",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "State Wise Free Job Alert",
    headingBgColor: "bg3",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "Andaman Nicobar",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/andaman-nicobar/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Andhra Pradesh",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-andhra-pradesh/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Assam",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-assam/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Arunachal Pradesh",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-arunachal-pradesh/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Bihar",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-bihar/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Bihar",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-bihar/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Chhattisgarh",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-chhattisgarh/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Dadra Nagar ",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/dadra-nagar-haveli/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Delhi",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-delhi/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Goa",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-goa/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Gujarat",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-gujarat/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Haryana",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-haryana/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Himachal Pradesh",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-himachal-pradesh/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Jammu Kashmir",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-jammu-and-kashmir/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Jharkhand",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-jharkhand/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Karnataka",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-karnataka/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Kerala",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-kerala/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Ladakh",
              linkUrl: "https://www.sarkariresultnaukri.com/states/ladakh/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Lakshadweep",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/lakshadweep/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Madhya Pradesh",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-mp-madhya-pradesh/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Maharashtra",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-maharashtra/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Manipur",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-manipur/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Meghalaya",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-meghalaya/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Mizoram",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-mizoram/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Nagaland",
              linkUrl:
                "http://www.sarkarinaukrisarch.in/states/jobs-in-nagaland/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Orissa",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-orissa/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Puducherry",
              linkUrl: "https://www.sarkariresultnaukri.com/states/puducherry/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Punjab",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-punjab/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Rajasthan",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-rajasthan/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Sikkim",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-sikkim/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Tamil Nadu",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-tamilnadu/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Telangana",
              linkUrl: "https://www.sarkariresultnaukri.com/states/telangana/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Tripura",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-tripura/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Uttar Pradesh",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-uttar-pradesh/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Uttarakhand",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-uttarakhand/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "West Bengal",
              linkUrl:
                "https://www.sarkariresultnaukri.com/states/jobs-in-west-bengal/",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "Free Job Alert By Job Role",
    headingBgColor: "bg4",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "Account Officer",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/account-officer/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Accountant",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/accountant/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Apprentice",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/apprentice/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Assistant Engineer",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/engineer/",
            },
          ],
        },
        { link: [{ linkName: "Assistant Manager", linkUrl: "undefined" }] },
        { link: [{ linkName: "Assistant Professor", linkUrl: "undefined" }] },
      ],
      [
        { link: [{ linkName: "Associate Professor", linkUrl: "undefined" }] },
        { link: [{ linkName: "Civil Judge", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Clerk",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/clerk/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Computer Operator",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/computer-operator/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Constable Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/constable-police/",
            },
          ],
        },
        { link: [{ linkName: "Consultant", linkUrl: "undefined" }] },
      ],
      [
        { link: [{ linkName: "Data Entry Operator", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Diploma Apprentice",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/diploma-apprentice/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "District Judge",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/district-judges/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Draughtsman",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/draughtsman/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Driver",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/driver/",
            },
          ],
        },
        { link: [{ linkName: "Executive", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            {
              linkName: "Executive Trainee",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/executive-trainee/",
            },
          ],
        },
        { link: [{ linkName: "Field Assistant", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Fireman",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/fireman/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "GDS",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/gds-gramin-dak-sevak/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Graduate Apprentice",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/graduate-apprentice/",
            },
          ],
        },
        { link: [{ linkName: "Graduate Trainee", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            {
              linkName: "Group D",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/group-d/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "ITI Apprentice",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/iti-apprentice/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "JRF",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/jrf-junior-research-fellowship/",
            },
          ],
        },
      ],
      [
        { link: [{ linkName: "Junior Assistant", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Junior Engineer",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/junior-engineer/",
            },
          ],
        },
        { link: [{ linkName: "Junior Executive", linkUrl: "undefined" }] },
      ],
      [
        { link: [{ linkName: "Junior Manager", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Junior Resident",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/junior-resident/",
            },
          ],
        },
        { link: [{ linkName: "Junior Scientist", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            { linkName: "Junior Technical Officer", linkUrl: "undefined" },
          ],
        },
        { link: [{ linkName: "Junior Technician", linkUrl: "undefined" }] },
        { link: [{ linkName: "Lab Attendant", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            {
              linkName: "Lab Technician",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/lab-technician/",
            },
          ],
        },
        { link: [{ linkName: "Laboratory Supervisor", linkUrl: "undefined" }] },
        { link: [{ linkName: "Law Officer", linkUrl: "undefined" }] },
      ],
      [
        { link: [{ linkName: "LDC CLerk", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Librarian",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/librarian/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Lineman",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/lineman/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Management Trainee",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/management-trainee/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Manager",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/manager/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Medical Officer",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/medical-officer/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "MTS",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/mts-multi-tasking/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Office Assistant",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/office-assistant/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "OT Technician",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/ot-technician/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Paramedical",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/paramedical/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Pharmacist",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/pharmacist/",
            },
          ],
        },
        { link: [{ linkName: "Professor", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            {
              linkName: "Programmer",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/programmer/",
            },
          ],
        },
        { link: [{ linkName: "Project Associate", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Project Engineer",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/project-engineer/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Radiographer",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/radiographer/",
            },
          ],
        },
        { link: [{ linkName: "Registrar", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Research Officer",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/research-officer/",
            },
          ],
        },
      ],
      [
        { link: [{ linkName: "Scientific Assistant", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Scientist",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/scientist/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Security Guard",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/security-guard/",
            },
          ],
        },
      ],
      [
        { link: [{ linkName: "Security Officer", linkUrl: "undefined" }] },
        { link: [{ linkName: "Semi Skilled", linkUrl: "undefined" }] },
        { link: [{ linkName: "Senior Assistant", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            {
              linkName: "Senior Resident",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/senior-resident/",
            },
          ],
        },
        { link: [{ linkName: "Senior Technician", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Skilled",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/skilled/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "SRF",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/srf-senior-research-fellow/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Staff Nurse",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/nurse/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Stenographer",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/stenographer/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Supervisor",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/supervisor/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Teacher",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/teacher/",
            },
          ],
        },
        { link: [{ linkName: "Technical Assistant", linkUrl: "undefined" }] },
      ],
      [
        { link: [{ linkName: "Technical Officer", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Technician",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/technician/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Translator",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/translator/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Typist",
              linkUrl: "https://www.sarkariresultnaukri.com/job-role/typist/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "UDC Clerk",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/udc-upper-division-clerk/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Unskilled",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/unskilled/",
            },
          ],
        },
      ],
      [
        { link: [{ linkName: "Veterinary Officer", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Xray Technician",
              linkUrl:
                "https://www.sarkariresultnaukri.com/job-role/xray-technician/",
            },
          ],
        },
        { link: [{ linkName: "Young Professionals", linkUrl: "" }] },
      ],
    ],
  },
  {
    heading: "UPSC & State PSC FreeJobAlert",
    headingBgColor: "bg3",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "UPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/upsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Andhra PSC",
              linkUrl: "https://www.sarkariresultnaukri.com/pscap-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Arunachal",
              linkUrl:
                "https://www.sarkariresultnaukri.com/arunachal-pradesh-psc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "APSC",
              linkUrl: "https://www.sarkariresultnaukri.com/apsc-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "BPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/bpsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "CGPSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/chhattisgarh-psc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Goa PSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/goa-psc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "GPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/gpsc-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "HPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/hpsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "HPPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/hppsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "JKPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/jkpsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "JPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/jpsc-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "KPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/kpsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Kerala PSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/kerala-psc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "MPPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/mppsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "MPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/mpsc-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Manipur PSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/manipur-psc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Meghalaya PSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/meghalaya-psc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Mizoram PSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/mizoram-psc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Nagaland PSC",
              linkUrl: "https://www.sarkariresultnaukri.com/npsc-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "OPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/opsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "PPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/ppsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "RPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/rpsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "SPSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/sikkim-psc-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "TNPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/tnpsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "TSPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/tspsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "TPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/tpsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "UPPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/uppsc-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "",
              linkUrl: "",
            },
          ],
        },
        {
          link: [
            {
              linkName: "UKPSC",
              linkUrl: "https://www.sarkariresultnaukri.com/ukpsc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "PSCWB",
              linkUrl: "https://www.sarkariresultnaukri.com/pscwb-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "",
              linkUrl: "",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "Banking & Govt Financial Sector Free Job Alert",
    headingBgColor: "bg4",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "IBPS",
              linkUrl: "https://www.sarkariresultnaukri.com/ibps-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "LIC",
              linkUrl: "https://www.sarkariresultnaukri.com/lic-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "RBI",
              linkUrl: "https://www.sarkariresultnaukri.com/rbi-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "SBI",
              linkUrl: "https://www.sarkariresultnaukri.com/sbi-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "NABARD",
              linkUrl:
                "https://www.sarkariresultnaukri.com/nabard-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "EPFO",
              linkUrl: "https://www.sarkariresultnaukri.com/epfo-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "UIIC",
              linkUrl: "https://www.sarkariresultnaukri.com/uiic-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "NIACL",
              linkUrl: "https://www.sarkariresultnaukri.com/niacl-recruitment/",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "Staff & Sub Ordinate Selections",
    headingBgColor: "bg3",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "SSC",
              linkUrl: "https://www.sarkariresultnaukri.com/ssc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "APSSB",
              linkUrl: "https://www.sarkariresultnaukri.com/apssb-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "BSSC",
              linkUrl: "https://www.sarkariresultnaukri.com/bssc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "DSSSB",
              linkUrl:
                "https://www.sarkariresultnaukri.com/dsssb-online-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "GSSSB",
              linkUrl: "https://www.sarkariresultnaukri.com/gsssb-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "HPSSSB",
              linkUrl:
                "https://www.sarkariresultnaukri.com/hpsssb-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "HSSC",
              linkUrl: "https://www.sarkariresultnaukri.com/hssc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "JKSSB",
              linkUrl: "https://www.sarkariresultnaukri.com/jkssb-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "JSSC",
              linkUrl: "https://www.sarkariresultnaukri.com/jssc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "OSSC",
              linkUrl: "https://www.sarkariresultnaukri.com/ossc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "OSSSC",
              linkUrl: "https://www.sarkariresultnaukri.com/osssc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "PSSSB",
              linkUrl: "https://www.sarkariresultnaukri.com/psssb-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "RSMSSB",
              linkUrl:
                "https://www.sarkariresultnaukri.com/rsmssb-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "UKSSSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/uksssc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "UPSSSC",
              linkUrl:
                "https://www.sarkariresultnaukri.com/upsssc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "",
              linkUrl: "",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "Defense & Central Police Force Free Job Alert",
    headingBgColor: "bg4",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "Join Indian Army",
              linkUrl:
                "https://www.sarkariresultnaukri.com/join-indian-army-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Indian Air Force",
              linkUrl:
                "https://www.sarkariresultnaukri.com/indian-air-force-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Join Indian Navy",
              linkUrl:
                "https://www.sarkariresultnaukri.com/join-indian-navy-recruitment/",
            },
          ],
        },
        {
          link: [{ linkName: "Join Indian Coast Guard", linkUrl: "undefined" }],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Assam Rifles",
              linkUrl:
                "https://www.sarkariresultnaukri.com/assam-rifles-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "BSF",
              linkUrl: "https://www.sarkariresultnaukri.com/bsf-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "CISF",
              linkUrl: "https://www.sarkariresultnaukri.com/cisf-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "CRPF",
              linkUrl: "https://www.sarkariresultnaukri.com/crpf-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "IB MHA",
              linkUrl:
                "https://www.sarkariresultnaukri.com/ib-mha-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "ITBP",
              linkUrl: "https://www.sarkariresultnaukri.com/itbp-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "OFB",
              linkUrl: "https://www.sarkariresultnaukri.com/ofb-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "RPF",
              linkUrl: "https://www.sarkariresultnaukri.com/rpf-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "",
              linkUrl: "",
            },
          ],
        },
        {
          link: [
            {
              linkName: "SSB",
              linkUrl:
                "https://www.sarkariresultnaukri.com/sashastra-seema-bal-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Indian Army Rally",
              linkUrl:
                "https://www.sarkariresultnaukri.com/indian-army-recruitment-rally/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "",
              linkUrl: "",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "State Police Free Job Alert",
    headingBgColor: "bg3",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "AP Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/slprb-ap-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Andaman Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/andaman-nicobar-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Arunachal Pradesh Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/arunachal-pradesh-police-recruitment/",
            },
          ],
        },
      ],
      [
        { link: [{ linkName: "Assam Police", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "CSBC Bihar Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/csbc-bihar-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "BPSSC Bihar Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/bpssc-bihar-police-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Chandigarh Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/chandigarh-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "CG Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/cg-police-recruitment/",
            },
          ],
        },
        { link: [{ linkName: "Delhi Police", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            {
              linkName: "Goa Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/goa-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Gujarat Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/gujarat-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Haryana Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/haryana-police-recruitment/",
            },
          ],
        },
      ],
      [
        { link: [{ linkName: "HP Police", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "JK Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/jk-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "JH Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/jh-police-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "KSP Karnataka Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/ksp-karnataka-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Kerala Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/kerala-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "MP Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/mp-police-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Maharshtra Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/maharashtra-police-recruitment/",
            },
          ],
        },
        { link: [{ linkName: "Manipur Police", linkUrl: "undefined" }] },
        { link: [{ linkName: "Meghalaya Police", linkUrl: "undefined" }] },
      ],
      [
        { link: [{ linkName: "Nagaland Police", linkUrl: "undefined" }] },
        { link: [{ linkName: "OPSSB Odisha", linkUrl: "undefined" }] },
        { link: [{ linkName: "Puducherry Police", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            {
              linkName: "Punjab Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/punjab-police-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Rajasthan Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/rajasthan-police-recruitment/",
            },
          ],
        },
        { link: [{ linkName: "Sikkim Police", linkUrl: "undefined" }] },
      ],
      [
        { link: [{ linkName: "TamilNadu Police", linkUrl: "undefined" }] },
        { link: [{ linkName: "TSLPRB", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Tripura Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/tripura-police-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "UPPRPB UP Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/upprpb-up-police-recruitment/",
            },
          ],
        },
        { link: [{ linkName: "Uttarakhand Police", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "West Bengal Police",
              linkUrl:
                "https://www.sarkariresultnaukri.com/wb-police-recruitment/",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "Mahatrana & Navratna PSUs",
    headingBgColor: "bg4",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "BEL",
              linkUrl: "https://www.sarkariresultnaukri.com/bel-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "BHEL",
              linkUrl: "https://www.sarkariresultnaukri.com/bhel-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "BPCL",
              linkUrl: "https://www.sarkariresultnaukri.com/bpcl-recruitment/",
            },
          ],
        },
        { link: [{ linkName: "Coal India", linkUrl: "undefined" }] },
      ],
      [
        {
          link: [
            {
              linkName: "CONCOR",
              linkUrl:
                "https://www.sarkariresultnaukri.com/concor-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "EIL",
              linkUrl:
                "https://www.sarkariresultnaukri.com/engineers-india-eil-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "GAIL",
              linkUrl: "https://www.sarkariresultnaukri.com/gail-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "HAL",
              linkUrl: "https://www.sarkariresultnaukri.com/hal-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "HPCL",
              linkUrl: "https://www.sarkariresultnaukri.com/hpcl-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "IOCL",
              linkUrl: "https://www.sarkariresultnaukri.com/iocl-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "MTNL",
              linkUrl: "https://www.sarkariresultnaukri.com/mtnl-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "NALCO",
              linkUrl: "https://www.sarkariresultnaukri.com/nalco-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "NBCC",
              linkUrl: "https://www.sarkariresultnaukri.com/nbcc-recruitment/",
            },
          ],
        },
        { link: [{ linkName: "NLC India", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "NMDC",
              linkUrl: "https://www.sarkariresultnaukri.com/nmdc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "NTPC",
              linkUrl: "https://www.sarkariresultnaukri.com/ntpc-recruitment/",
            },
          ],
        },
      ],
      [
        { link: [{ linkName: "OIL India", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "ONGC",
              linkUrl: "https://www.sarkariresultnaukri.com/ongc-recruitment/",
            },
          ],
        },
        { link: [{ linkName: "PFC India", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "PGCIL",
              linkUrl: "https://www.sarkariresultnaukri.com/pgcil-recruitment/",
            },
          ],
        },
      ],
      [
        { link: [{ linkName: "REC India", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Vizag Steel",
              linkUrl:
                "https://www.sarkariresultnaukri.com/vizag-steel-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "SAIL",
              linkUrl: "https://www.sarkariresultnaukri.com/sail-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "SCI",
              linkUrl:
                "https://www.sarkariresultnaukri.com/sci-ship-india-recruitment/",
            },
          ],
        },
      ],
    ],
  },
  {
    heading: "Court FreeJobAlert",
    headingBgColor: "bg3",
    headingAlignment: "center",
    rowsAlignment: "center",
    data: [
      [
        {
          link: [
            {
              linkName: "Supreme Court India",
              linkUrl:
                "https://www.sarkariresultnaukri.com/supreme-court-of-india-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Allahabad High",
              linkUrl:
                "https://www.sarkariresultnaukri.com/allahabad-high-court-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Bombay High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/bombay-high-court-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Calcutta High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/calcutta-high-court-recruitment/",
            },
          ],
        },
        { link: [{ linkName: "Delhi High Court", linkUrl: "undefined" }] },
        {
          link: [
            {
              linkName: "Gauhati High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/ghc-online-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Gujarat High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/gujarat-high-court-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "High Court AP",
              linkUrl: "https://www.sarkariresultnaukri.com/hc-ap-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "High Court Telangana",
              linkUrl: "https://www.sarkariresultnaukri.com/hc-ts-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "High Court Kerala",
              linkUrl: "https://www.sarkariresultnaukri.com/hck-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "HCRAJ",
              linkUrl: "https://www.sarkariresultnaukri.com/hcraj-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "HCM Imphal",
              linkUrl:
                "https://www.sarkariresultnaukri.com/hcm-imphal-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "High Court CG",
              linkUrl:
                "https://www.sarkariresultnaukri.com/high-court-cg-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "HP High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/hp-high-court-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "High Court CHD",
              linkUrl:
                "https://www.sarkariresultnaukri.com/high-court-chd-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "High Court Sikkim",
              linkUrl:
                "https://www.sarkariresultnaukri.com/high-court-sikkim-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "High Court Uttarakhand",
              linkUrl:
                "https://www.sarkariresultnaukri.com/high-court-uttarakhand-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Jharkhand High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/jharkhand-high-court-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "JK High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/jk-high-court-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Karnataka Judiciary",
              linkUrl:
                "https://www.sarkariresultnaukri.com/karnataka-judiciary-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "MHC TN",
              linkUrl:
                "https://www.sarkariresultnaukri.com/mhc-tn-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "MPHC",
              linkUrl: "https://www.sarkariresultnaukri.com/mphc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Meghalaya High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/meghalaya-high-court-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "Orissa High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/orissa-high-court-recruitment/",
            },
          ],
        },
      ],
      [
        {
          link: [
            {
              linkName: "Patna High Court",
              linkUrl:
                "https://www.sarkariresultnaukri.com/patna-high-court-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "THC",
              linkUrl: "https://www.sarkariresultnaukri.com/thc-recruitment/",
            },
          ],
        },
        {
          link: [
            {
              linkName: "eCourt",
              linkUrl:
                "https://www.sarkariresultnaukri.com/e-courts-recruitment/",
            },
          ],
        },
      ],
    ],
  },
]
export default TableData
