const globalState = {
  clicked: false,
  data: {
    currentUser: true,
    name: "",
    linkUrl: "",
    emailsList: [
      "hiyasoni7",
      "akashsaini134",
      "hardikbiyawat",
      "mehtagargi96",
      "chouhanrashika10",
      "gurudattvyas",
      "priyanka96talesara",
      "roshnivaya72",
      "salonipandya15",
      "deepakvaishnav8880",
      "rajeshjoshirj1994",
      "raviregar0",
      "mohitpatel410",
      "mhtkandpal",
      "chirag.samar27",
      "impuneet777",
      "tvrsha",
      "parvatsing46",
      "narpatsinghpurohit07",
      "gautam.swapnil7",
      "vishalsinghofficial77",
      "joshihimshikha",
      "michaelamit774",
      "ayushbansal506",
      "chayansamar",
      "deepikajeenger0007",
      "viraj_2894",
      "simoni321gupta",
      "ragheshdutt11",
      "jayeshjoshi000",
      "kumarjayesh.82396",
      "jalpakanara19",
      "nehadhaker887",
      "pateljigar345",
      "mahanbharat92",
      "shubhamvyas4",
      "honey1234999",
      "shrutisoni20.ss",
      "bhk346422",
      "visitparam",
      "hiteshmudaliar1996",
      "psg011194",
      "aastha108lalawat",
      "saprusaku80",
      "iiaammsahil77",
      "ernitesh6",
      "liaquathussain20",
      "rshubani7",
      "smona8455",
      "imanji94",
      "jyotiudawat03",
      "manishwanishekhawat",
      "rajat2128",
      "rahul099.rs",
      "surinderkotwal830",
      "vickeypaliwal555",
      "shivanityagi686",
      "gauripandya86",
      "tsusheel91",
      "opsharma32549",
      "sachetsingh55",
      "soninirbhay",
      "abhishekprasad",
      "dimplesohil90",
      "durgmahida",
      "ametagagan168",
      "trivedigopal666",
      "harshcool.jain7",
      "jain.harshad228",
      "ishan.bhatti.3",
      "Kanishkgaur101093",
      "manikpokhetra",
      "mohdramzaan112",
      "Moni.mudit",
      "prinkabhardwaj4",
      "priyankpatel147",
      "Rishabh.audichya05",
      "shantams45",
      "shreyanskothari1995",
      "nairsoumya1996",
      "surajbhat1997",
      "vaibhav.chaudhary6",
      "vgokhale21",
      "vijaymathur948",
      "vikassharma24243",
      "hiloriya.vishal",
      "zaidhussainrazvi1996",
      "amishalalawat20",
      "ankitpalcs2",
      "das.ashutosh908",
      "chandrapalsingh.jhala1997",
      "harshitaiyer8390",
      "gaurmanan",
      "pareexitsingh",
      "richaupadhyay448",
      "upadhyaysachin1996.su",
      "vaishnav.sangeeta10",
      "twinklesinghal54321",
      "ujjwalkumar8797348744",
      "anikitkothari",
      "muzibmakrani",
      "rockingrathore123",
      "gksourav2",
      "arjun.as483",
      "bannasunny7773",
    ],
    users: {},
  },
}

export default globalState
