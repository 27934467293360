import React, { Component } from "react"
import HeaderComponent from "./TopComponent/HeaderComponent"
import BodyComponent from "./BodyComponent/BodyComponent"
class index extends Component {
  projectInfo() {
    return {
      projectTitle: "A Small Webpage of react resources ",
      projectDescription: "A small left sidebar of React Resouces",
      referenceUrl: "https://reactresources.com/topics/getting-started",
      developer: "Vijay Mathur",
      createdAt: "11-04-2021",
      completedAt: "11-04-2021",
    }
  }
  render() {
    return (
      <div>
        <HeaderComponent />
        <BodyComponent />
      </div>
    )
  }
}

export default index
