import { Component } from "react"

class index extends Component {
  projectInfo() {
    return {
      projectTitle: "Let's see behind the library",
      projectDescription: "I was curious to know what is behind the library.",
      referenceUrl:
        "https://vijaymathur948.github.io/lets-see-behind-the-library/",
      createdAt: "17-03-2021",
      completedAt: "20-03-2021",
    }
  }
}

export default index
