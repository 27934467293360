import React from "react"
import "./style.css"

const TableComponent = ({ tableData, isLastTable }) => {
  return (
    <div className='table-content'>
      <table className='table'>
        <tbody>
          <tr className={`table-heading ${tableData.headingBgColor}`}>
            <th
              colSpan={tableData.data[0] ? tableData.data[0].length : 0}
              className={
                tableData.headingAlignment === "center"
                  ? "text-align-center"
                  : ""
              }
            >
              {tableData.heading}
            </th>
          </tr>
          {tableData.data.map((row, rowIndex) => {
            return (
              <tr
                key={rowIndex}
                className={
                  tableData.rowsAlignment === "center"
                    ? "text-align-center"
                    : ""
                }
              >
                {row.map((column, columnIndex) => {
                  return (
                    <React.Fragment key={columnIndex}>
                      {column["text"] !== undefined && (
                        <td>
                          <strong>{column["text"]}</strong>
                        </td>
                      )}
                      {column["link"] !== undefined && (
                        <td className='table-link'>
                          {column["link"].map((eachLink, eachLinkIndex) => {
                            return (
                              <React.Fragment key={eachLinkIndex}>
                                <a
                                  href={eachLink.linkUrl}
                                  title={eachLink.linkName}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  {eachLink.linkName}
                                </a>
                                {column["link"].length > 1 && (
                                  <span>&nbsp; | &nbsp;</span>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </td>
                      )}
                    </React.Fragment>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <hr />
      {isLastTable && <hr className='horizontal-line' />}
    </div>
  )
}
export default TableComponent
