import styles from "./style.module.css"
import logo from "../../../logo.svg"
import SearchIcon from "../assets/SearchIcon.svg"

const HeaderComponent = () => {
  return (
    <div className={styles.header_component}>
      <div className={styles.header}>
        <div className={styles.first_block}>
          <img src={logo} alt='' />
          <h3 className={styles.url}>REACT RESOURCES</h3>
        </div>
        <div className={styles.second_block}>
          <input type='text' autoComplete='true' placeholder='Search' />
          <img src={SearchIcon} alt='' />
        </div>
      </div>
    </div>
  )
}

export default HeaderComponent
