import "./style.css"

const BreadCrumb = () => {
  return (
    <div className='breadcrumb'>
      <div className='inner-breadcrumb'>
        <p>You are here &nbsp; : </p>
        <p>
          <a href={document.location.pathname} title='Sarkari Naukri Result'>
            Home &nbsp;
          </a>
          »
        </p>
        <p>
          Free Job ALert 2020 - WhatsApp, Telegram, Email Free Job alert 2021{" "}
        </p>
      </div>
    </div>
  )
}

export default BreadCrumb
